/**
 * Custom hook to manage payment status. It handles the confirm of payments and the related state of payment.
 *
 * @param {string} [type='single'] - The type of payment, either 'single' or 'recurrent'.
 * @returns {Object} - An object containing payment state, status, and handlers.
 * @property {Object} paymentState - The current state of the payment.
 * @property {Function} setPaymentState - Function to update the payment state.
 * @property {boolean|null} isSuccessfulPayment - Indicates if the payment was successful.
 * @property {boolean} isWaitingForPayment - Indicates if the payment is waiting.
 * @property {boolean} isIdleForPayment - Indicates if the payment is idle.
 * @property {Function} setIsSuccessfulPayment - Function to set the success status of the payment.
 * @property {Function} handlePaymentSuccess - Handler for successful payment.
 * @property {Function} handlePaymentError - Handler for payment error.
 *
 * @example
 * const {
 *  paymentState,
 * setPaymentState,
 * isSuccessfulPayment,
 * isWaitingForPayment,
 * isIdleForPayment,
 * setIsSuccessfulPayment,
 * handlePaymentSuccess,
 * handlePaymentError,
 * } = usePaymentStatus('single')
 */
import { usePayStore } from '@/app/(dona)/layout'
import { FIELDNAMES } from '@/components/molecules/Form/utils/constants'
import axiosInstance from '@/utils/axiosWrapper'
import {
  PAYMENT_STATES,
  RECURRENT_PAYMENT_STATUS_KEY,
  SINGLE_PAYMENT_STATUS_KEY,
} from '@/utils/constants'
import { useLocalStorageState } from 'ahooks'
import { useSearchParams } from 'next/navigation'
import { useCallback, useEffect, useMemo, useState } from 'react'

const {
  DONATION_IN_MEMORY_RECEIVER_NAME,
  DONATION_IN_MEMORY_DATE,
  DONATION_IN_MEMORY_LETTER_TO_RELATIVES,
  DONATION_LETTER_PARTICIPANTS,
  DONATION_LETTER_RECIPIENT_NAME,
  DONATION_LETTER_RECIPIENT_EMAIL,
  DONATION_LETTER_RECIPIENT_COURTESY_TITLE,
  DONATION_LETTER_RECIPIENT_ADDRESS,
  DONATION_LETTER_RECIPIENT_ADDRESS_NUMBER,
  DONATION_LETTER_RECIPIENT_POSTAL_CODE,
  DONATION_LETTER_RECIPIENT_CITY,
  DONATION_LETTER_RECIPIENT_PROVINCE,
  DONATION_LETTER_RECIPIENT_COUNTRY,
} = FIELDNAMES

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const usePaymentStatus = (type = 'single') => {
  const searchParams = useSearchParams()
  const { store, setStore } = usePayStore()
  const isSingleDonation = type === 'single' ? true : false
  const localStorageKey = isSingleDonation
    ? SINGLE_PAYMENT_STATUS_KEY
    : RECURRENT_PAYMENT_STATUS_KEY

  const [paymentState, setPaymentState] = useLocalStorageState(
    localStorageKey,
    {
      listenStorageChange: true,
      defaultValue: {
        state: PAYMENT_STATES.IDLE,
        info: {},
      },
    }
  )
  const [isSuccessfulPayment, setIsSuccessfulPayment] = useState(null)

  const isIdleForPayment = paymentState?.state === PAYMENT_STATES.IDLE
  const isWaitingForPayment =
    paymentState?.state === PAYMENT_STATES.WAITING_FOR_PAYMENT

  const queryParams = useMemo(() => {
    const params = {}
    searchParams.forEach((value, key) => {
      params[key] = value
    })
    return params
  }, [searchParams])

  const handlePaymentSuccess = useCallback(() => {
    setPaymentState({
      ...paymentState,
      state: PAYMENT_STATES.PAYMENT_SUCCESS,
      oldInfo: { ...paymentState.info },
      info: isSingleDonation
        ? { ckey: queryParams.ckey, token: queryParams.token || null }
        : { ...queryParams },
    })
    window.close()
  }, [isSingleDonation, paymentState, queryParams, setPaymentState])

  const handlePaymentError = useCallback(() => {
    setPaymentState({
      ...paymentState,
      state: PAYMENT_STATES.PAYMENT_ERROR,
      oldInfo: { ...paymentState.info },
      info: isSingleDonation
        ? { ckey: queryParams.ckey, token: queryParams.token || null }
        : { ...queryParams },
    })
    window.close()
  }, [isSingleDonation, paymentState, queryParams, setPaymentState])

  const handleGetPaymentStatus = useCallback(async () => {
    const paymentStatus = await getPaymentStatus(
      isSingleDonation,
      queryParams,
      paymentState,
      store
    )
    return paymentStatus
  }, [isSingleDonation, queryParams, paymentState])

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      await sleep(2000)
      const paymentStatus = await handleGetPaymentStatus()
      setIsSuccessfulPayment(paymentStatus.success)
    }

    fetchPaymentStatus()
  }, [handleGetPaymentStatus, searchParams])

  useEffect(() => {
    const handlePageHide = () => {
      setPaymentState({
        state: PAYMENT_STATES.IDLE,
        method: '',
        info: {},
      })
    }

    if (isWaitingForPayment && isSuccessfulPayment !== null) {
      window.addEventListener('beforeunload', handlePageHide)
      if (isSuccessfulPayment) {
        window.removeEventListener('beforeunload', handlePageHide)
        handlePaymentSuccess()
        return
      }
      if (!isSuccessfulPayment) {
        window.removeEventListener('beforeunload', handlePageHide)
        handlePaymentError()
        return
      }
    }
    return () => window.removeEventListener('beforeunload', handlePageHide)
  }, [
    handlePaymentError,
    handlePaymentSuccess,
    isSuccessfulPayment,
    isWaitingForPayment,
    isIdleForPayment,
    paymentState,
    setPaymentState,
  ])

  return {
    paymentState,
    setPaymentState,
    isSuccessfulPayment,
    isWaitingForPayment,
    isIdleForPayment,
    setIsSuccessfulPayment,
    handlePaymentSuccess,
    handlePaymentError,
  }
}

async function getPaymentStatus(
  isSingleDonation = true,
  queryParams = {},
  paymentState = {},
  store = {}
) {
  if (!paymentState || !paymentState.state) {
    return { success: false, message: 'Missing payment state' }
  }
  const handleOnGetPaymentStatus = async (params) => {
    const _handleGetPaymentStatus = async (params) => {
      try {
        const response = await axiosInstance.post('/api/donation/confirm', {
          ...params,
          success: '1',
        })

        return {
          success: true,
          ...response.data.data,
        }
      } catch (error) {
        const errorResponse = error.response?.data ?? error
        console.error('Error getting payment status:', errorResponse)
        return {
          success: false,
          error: errorResponse,
        }
      }
    }
    const paymentStatus = await _handleGetPaymentStatus(params)
    return paymentStatus
  }
  if (isSingleDonation) {
    const hasCkey = queryParams?.ckey ?? false
    if (!hasCkey) {
      return { success: false, message: 'Missing ckey' }
    }
    const paymentStatus = await handleOnGetPaymentStatus(queryParams)
    await sleep(2000)
    return paymentStatus
  }

  const isWaitingForPayment =
    paymentState?.state === PAYMENT_STATES.WAITING_FOR_PAYMENT
  const isIdle = paymentState?.state === PAYMENT_STATES.IDLE
  const hasOperationNumber = queryParams?.operationnumber ?? false

  if (isIdle) return { success: false, message: 'Payment state is idle' }
  if (isWaitingForPayment) {
    if (!hasOperationNumber)
      return { success: false, message: 'Missing operation number' }
    if (hasOperationNumber) {
      const includeKeys = [
        'firstname',
        'lastname',
        'email',
        'taxcode',
        'companyname',
        'amount',
        'from',
        'operationdate',
        'operationnumber',
      ]
      const confirmParams = Object.entries(queryParams).filter(
        ([key, value]) => {
          if (value !== '' && value !== null) {
            return true
          }
          return false
        }
      )
      const _formValues = store?.formValues || {}
      const formValuesToMap = Object.entries(_formValues)
        .filter(([key, value]) => {
          const keyToInlcude = [
            DONATION_IN_MEMORY_RECEIVER_NAME,
            DONATION_IN_MEMORY_DATE,
            DONATION_LETTER_PARTICIPANTS,
            DONATION_LETTER_RECIPIENT_EMAIL,
            DONATION_LETTER_RECIPIENT_NAME,
            DONATION_LETTER_RECIPIENT_ADDRESS,
            DONATION_LETTER_RECIPIENT_ADDRESS_NUMBER,
            DONATION_LETTER_RECIPIENT_POSTAL_CODE,
            DONATION_LETTER_RECIPIENT_CITY,
            DONATION_LETTER_RECIPIENT_PROVINCE,
            DONATION_LETTER_RECIPIENT_COUNTRY,
          ]
          if (keyToInlcude.includes(key)) {
            return true
          }
          return false
        })
        .reduce((acc, [key, value]) => {
          acc[key] = value
          return acc
        }, {})

      // 'data_anniversario': formValuesToMap.donationInMemoryDate,
      // const formValuesMapped = {
      // Riferimento_defunto:
      //   formValuesToMap?.donationInMemoryReceiverName ||
      //   queryParams.Riferimento_defunto ||
      //   '',
      // Consenso_comunicazione: '1',
      // FLAG_GRUPPO:
      //   formValuesToMap?.donationLetterPartecipants?.length > 0
      //     ? 'S'
      //     : 'N' || 'N',
      // Id_Titolo: 'Non ho capito',
      // email_famiglia: formValuesToMap?.donationLetterRecipientEmail || '',
      // Nome: '',
      // Cognome: formValuesToMap?.donationLetterRecipientName || '',
      // Indirizzo: formValuesToMap?.donationLetterRecipientAddress || '',
      // Civico: formValuesToMap?.donationLetterRecipientAddressNumber || '',
      // Cap: formValuesToMap?.donationLetterRecipientAddressPostalCode || '',
      // Frazioni: '',
      // Localita: formValuesToMap?.donationLetterRecipientCity || '',
      // Provincia: formValuesToMap?.donationLetterRecipientProvince || '',
      // Nazione: formValuesToMap?.donationLetterRecipientCountry || 'IT',
      // }

      const confirmData = confirmParams.reduce((acc, [key, value]) => {
        if (key === 'from' || key === 'paymentmethod') {
          acc['paymentmethod'] = 'iframe'
          acc['from'] = 'iframe'
          return acc
        }
        acc[key] = value
        return acc
      }, {})

      if (!confirmData.companyname) {
        confirmData.companyname = null
      }

      const paymentStatus = await handleOnGetPaymentStatus({
        ...confirmData,
      })
      return paymentStatus
    }
    return { success: true, message: 'Payment is still pending' }
  }

  return { success: false, message: 'Payment error' }
}
