'use client'

import { useEffect, useReducer, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  IoArrowBackOutline,
  IoCheckmarkOutline,
  IoWarning,
} from 'react-icons/io5'

import Accordion from '../Accordion'
import EditSelector from './EditSelector'
import Fieldset from './Fieldset'
import FormRecapItem from './FormRecapItem'
import {
  clearAllEditFields,
  evaluateCondition,
  getAllWatchedFields,
  getDefaultValues,
  getFieldNamesInRecapAccordion,
  getFieldNamesInView,
  getFieldsEdits,
  groupFieldsByEdit,
  groupFieldsByStep,
  isLetterPreviewStep,
  isPaymentStep,
  isRecurrencePaymentStep,
} from './utils/functions'

import { usePayStore } from '@/app/(dona)/layout'
import useRecursivePaymentHandler from '@/hooks/useRecursivePaymentHandler'
import { PAYMENT_STATES } from '@/utils/constants'
import { Slide, ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ErrorModal from '../Modal/ErrorModal'
import InMemoryLetterPreviewModal from '../Modal/InMemoryLetterPreviewModal'
import { FIELDNAMES, KNOWN_FORM_VALUES } from './utils/constants'
import {
  formInitialState,
  formReducer,
  reducerActions,
} from './utils/form-reducer'

const componentClassName = 'dynamic-form'

const {
  DONATION_AMOUNT,
  DONATION_PAYMENT_METHOD_ON_RECURRENCE,
  DONATOR_PAYMENT_METHOD,
  DONATION_PRIVACY,
} = FIELDNAMES

/**
 * Form component for managing a dynamic form.
 *
 * The Form component is designed to manage a dynamic form with multiple steps.
 * It handles form state, validation, and navigation between different steps.
 * The component also supports editing form fields, displaying errors,
 * and managing form submission.
 *
 * @param {Object} props - The properties of the component.
 * @param {Array} props.fields - The fields of the form.
 * @param {Object} props.config - The configuration of the form.
 * @param {Object} props.searchParams - The search parameters for the form.
 *
 * @returns {JSX.Element} The Form component.
 */

const Form = ({ fields: formFields, config, searchParams }) => {
  const [state, dispatch] = useReducer(formReducer, formInitialState)
  const stateReducer = { state, dispatch }
  const { store, setStore } = usePayStore()
  const [letterPreviewModal, setLetterPreviewModal] = useState(false)
  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: { ...getDefaultValues(formFields), ...searchParams },
  })
  const currentFormValues = formMethods.getValues()
  const {
    recursivePaymentState,
    setRecursivePaymentState,
    handleCreateRecurrentPayment,
    modalErrorIsOpen,
    setModalErrorIsOpen,
  } = useRecursivePaymentHandler({
    formData: currentFormValues,
  })

  const { stepConfig, editConfig, labels } = config
  const { currentStep, stepHistory, isEditMode, selectedEditGroup } = state
  const {
    formState: { errors },
    watch,
  } = formMethods

  const isCurrentStepPayment = isPaymentStep(currentStep, stepConfig)
  const isCurrentStepRecurrencePayment = isRecurrencePaymentStep(
    currentStep,
    stepConfig
  )
  const isLetterPreview = isLetterPreviewStep(currentStep, stepConfig)
  const oldFormValues = useRef(currentFormValues)
  const watchAllFields = watch()
  const fieldNameInViewToValidate = getFieldNamesInView(
    formFields,
    currentStep,
    watch
  )
  const isButtonDisabled = isEditMode
    ? Object.keys(errors).length > 0
    : Object.keys(errors).length > 0 &&
      Object.keys(errors).every((key) =>
        fieldNameInViewToValidate.includes(key)
      )

  const navigate = (direction) => {
    setStore({
      formValues: {
        ...store?.formValues,
        ...currentFormValues,
      },
    })
    if (direction === 'next') {
      const nextStep = getNextStep(currentStep, currentFormValues, stepConfig)
      if (nextStep !== null) {
        dispatch(
          reducerActions.SET_CURRENT_STEP({
            currentStep: nextStep,
            stepHistory: [...stepHistory, nextStep],
            direction: 'next',
          })
        )
      } else {
        console.debug('Form completato o nessun prossimo step disponibile.')
      }
    } else if (direction === 'previous') {
      const newHistory = [...stepHistory]
      const prevStep = goToPreviousStep(newHistory)

      dispatch(
        reducerActions.SET_CURRENT_STEP({
          currentStep: prevStep,
          stepHistory: newHistory,
          direction: 'previous',
        })
      )
    }
  }

  const notifyAcceptTerms = () => {
    toast.warn(
      "Devi accettare l'informativa sul trattamento dei dati personali ",
      {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: <IoWarning />,
      },
      { delay: 500 }
    )
  }

  const notifyOnSuccess = () => {
    toast.success(
      'Modifiche salvate con successo',
      {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: <IoCheckmarkOutline />,
      },
      { delay: 500 }
    )
  }

  const handleNextStep = async () => {
    const { trigger } = formMethods
    const canGoNext = await trigger(fieldNameInViewToValidate)
    if (!canGoNext) return
    navigate('next')
  }

  const handlePreviousStep = () => {
    const { clearErrors } = formMethods
    clearErrors()
    navigate('previous')
  }

  const handleEditForm = (e) => {
    e.preventDefault()
    dispatch(reducerActions.HANDLE_EDIT_FORM())
  }

  const handleConfirmEdit = (e) => {
    e.preventDefault()
    dispatch(reducerActions.HANDLE_CONFIRM_EDIT())
  }

  // selector edit, is the view after the user clicks 'Modifica'
  const handleBackFromSelectorEdit = () => {
    const { clearErrors, reset, unregister } = formMethods
    clearAllEditFields(currentFormValues, unregister, clearErrors, reset)
    dispatch(reducerActions.HANDLE_BACK_FROM_SELECTOR_EDIT())
  }

  const handleOnSelectedEditGroup = (payload) => {
    dispatch(reducerActions.SET_SELECTED_EDIT_GROUP(payload))
  }

  const handleBackFromEditFields = (e) => {
    e.preventDefault()
    dispatch(reducerActions.HANDLE_BACK_FROM_FIELDS_EDIT())
  }

  const handleSaveEdit = (e) => {
    const { setValue, clearErrors, reset, unregister, trigger } = formMethods
    const edits = getFieldsEdits(currentFormValues, formFields)
    e.preventDefault()
    const editFieldNames = Object.keys(edits).map((fieldName) => fieldName)
    const isValid = trigger(editFieldNames)
    if (!isValid) return
    if (edits.editNumber > 0) {
      Object.entries(edits).forEach(([fieldName, field]) => {
        if (fieldName === 'editNumber') return
        setValue(fieldName, field.newValue)
      })
      notifyOnSuccess()
    }
    clearAllEditFields(currentFormValues, unregister, clearErrors, reset)
    dispatch(reducerActions.HANDLE_SAVE_EDIT({ editNumber: edits.editNumber }))
  }

  const handleCompleteRecurrenceDonation = async (e) => {
    e.preventDefault()
    const isPrivacyAccepted =
      currentFormValues[DONATION_PRIVACY] === String(KNOWN_FORM_VALUES.TRUE) ||
      currentFormValues[DONATION_PRIVACY] === KNOWN_FORM_VALUES.TRUE ||
      false
    if (!isPrivacyAccepted) {
      notifyAcceptTerms()
      return
    }
    const { trigger } = formMethods
    const canGoNext = await trigger(fieldNameInViewToValidate)
    if (!canGoNext) return
    setStore({
      ...store,
      formValues: {
        ...store?.formValues,
        ...currentFormValues,
      },
    })
    const res = await handleCreateRecurrentPayment(e)
    return
  }

  const handlers = {
    handleNextStep,
    handlePreviousStep,
    handleEditForm,
    handleConfirmEdit,
    handleBackFromSelectorEdit,
    handleBackFromEditFields,
    handleSaveEdit,
    handleCompleteRecurrenceDonation,
  }

  const commonProps = {
    reducer: {
      state: {
        ...state,
        isCurrentStepPayment,
        isCurrentStepRecurrencePayment,
        isButtonDisabled,
      },
      dispatch,
    },
  }

  useEffect(() => {
    for (const target of fieldNameInViewToValidate) {
      if (watchAllFields?.[target] !== oldFormValues?.current?.[target]) {
        formMethods.trigger(target)
      }
    }
    oldFormValues.current = watchAllFields
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields])

  if (!state) return null
  return (
    <FormProvider {...formMethods}>
      <form
        id='dynamic-form'
        className={componentClassName}
      >
        {renderRecapAccordion(commonProps.reducer, formMethods, formFields)}
        {renderEditForm(commonProps.reducer, formMethods, formFields)}
        {renderStepForm(commonProps.reducer, formMethods, formFields)}
        {renderErrorModal(
          modalErrorIsOpen,
          setModalErrorIsOpen,
          recursivePaymentState,
          setRecursivePaymentState
        )}
        <ToastContainer
          position='bottom-center'
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
          transition={Slide}
        />
        {isEditMode && !selectedEditGroup ? (
          <EditSelector
            {...editConfig}
            formFieldsProps={formFields}
            onSelectedGroup={handleOnSelectedEditGroup}
            currentFormStep={currentStep}
          />
        ) : null}
        {isLetterPreview && !isEditMode ? (
          <InMemoryLetterPreviewModal
            isOpen={letterPreviewModal}
            onClose={() => setLetterPreviewModal(false)}
            onOpen={(e) => {
              e.preventDefault()
              setLetterPreviewModal(true)
            }}
          />
        ) : null}
        {renderSubmitWrapper(
          commonProps.reducer,
          formMethods,
          formFields,
          handlers,
          labels
        )}
      </form>
    </FormProvider>
  )
}

function getNextStep(currentStep, formValues, stepConfig) {
  const step = stepConfig[currentStep]
  if (!step || !step.next) return null

  for (const transition of step.next) {
    if (transition.condition) {
      const evaluetedResult = evaluateCondition(
        transition.condition,
        formValues
      )
      if (!evaluetedResult) continue
      if (evaluetedResult) {
        return transition.targetStep
      }
    }
    return transition.targetStep
  }

  return null
}

function goToPreviousStep(stepHistory) {
  if (stepHistory.length > 1) {
    // Rimuovi l'ultimo step (quello corrente)
    stepHistory.pop()
    // Lo step corrente diventa il nuovo ultimo elemento
    return stepHistory[stepHistory.length - 1]
  } else {
    // Se siamo allo step iniziale, non possiamo andare indietro
    return stepHistory[0]
  }
}

function getShowCurrentEditAccordion(changes) {
  if (changes.editNumber < 1) {
    return {
      title: 'Nessuna modifica effettuata',
      content: (
        <div className={'recap'}>
          <FormRecapItem
            label={'Nessuna modifica effettuata'}
            value={''}
          />
        </div>
      ),
    }
  }

  const coloredTitle = `${changes.editNumber}${
    changes.editNumber > 1 ? '+' : ''
  } ${changes.editNumber === 1 ? 'modifica' : 'modifiche'}`

  return {
    title: 'Hai effettuato',
    coloredTitle,
    content: (
      <div className='recap'>
        {Object.entries(changes).map(([fieldName, field]) => (
          <FormRecapItem
            key={fieldName}
            label={field.label}
            value={field.newValue}
            oldValue={field.oldValue}
            {...field}
          />
        ))}
      </div>
    ),
  }
}

function renderRecapAccordion(reducer, formMethods, formFields) {
  const { state, dispatch } = reducer
  const {
    currentStep,
    isEditMode,
    recapAccordionState,
    forceCloseRecap,
    recapTopLabel,
    stepHistory,
    isCurrentStepPayment,
    isCurrentStepRecurrencePayment,
  } = state
  const { watch, getValues } = formMethods
  const isFirstStep = currentStep === 1

  const fieldsInRecapAccordion = getFieldNamesInRecapAccordion(
    stepHistory,
    formFields,
    watch,
    getValues
  )

  const handleRecapToggle = (index) => {
    dispatch(
      reducerActions.UPDATE_RECAP_ACCORDION_STATE({
        recapAccordionState: index,
      })
    )
  }

  const getContent = () => {
    return (
      <div
        className='recap'
        role='list'
      >
        {fieldsInRecapAccordion.map((field) => (
          <FormRecapItem
            key={field.fieldName}
            label={field.label}
            value={field.value}
            {...field}
          />
        ))}
      </div>
    )
  }

  return !isFirstStep && !isEditMode ? (
    <Accordion
      key={currentStep}
      isModalBehavior={true}
      closeAll={forceCloseRecap}
      topLabel={recapTopLabel}
      onToggle={handleRecapToggle}
      className={`${componentClassName}_recap-accordion ${
        (isCurrentStepPayment || isCurrentStepRecurrencePayment) &&
        recapAccordionState === false
          ? 'rotating-border'
          : ''
      }
      `}
      items={[
        {
          title: 'Riepilogo donazione',
          topLabel: recapTopLabel,
          content: getContent(),
        },
      ]}
    />
  ) : (
    <span></span>
  )
}

function renderEditForm(stateReducer, formMethods, formFields) {
  const { state, dispatch } = stateReducer
  const { selectedEditGroup, isEditMode, currentStep } = state

  const {
    getValues,
    formState: { errors },
  } = formMethods
  if (selectedEditGroup === null) return
  if (isEditMode && selectedEditGroup) {
    const fieldsGroupedByEdit = groupFieldsByEdit(formFields, currentStep)
    const groupedFields = {}
    const ungroupedFields = []
    const groupsLabel = {}
    const fieldsThatChangeValuesToOtherFields = new Set()

    // First, scan all form fields to find all watched fields
    formFields.forEach((field) => {
      const watchedFields = field.uiOptions?.watchFields || []
      if (watchedFields.length > 0) {
        watchedFields.forEach((watchedField) => {
          const { field: fieldToWatch } = watchedField
          fieldsThatChangeValuesToOtherFields.add(`${fieldToWatch}-edit`)
        })
      }
    })

    // Then process only the fields for the selected edit group
    const fields = fieldsGroupedByEdit[selectedEditGroup]
    fields.forEach((field) => {
      const group = field.uiOptions?.group
      const groupLabel = field.uiOptions?.editGroupLabel ?? false
      const editFieldName = field.fieldName + '-edit'
      const editField = {
        ...field,
        defaultValue: getValues(field.fieldName),
        value: getValues(field.fieldName),
        fieldName: editFieldName,
      }

      if (group) {
        if (!groupedFields[group]) {
          groupedFields[group] = []
        }
        if (!groupsLabel[group] && groupLabel) {
          groupsLabel[group] = groupLabel
        }
        groupedFields[group].push(editField)
      } else {
        ungroupedFields.push(editField)
      }
    })

    const cleanedUngroupedFields = ungroupedFields.filter(
      (field) => !fieldsThatChangeValuesToOtherFields.has(field.fieldName)
    )

    const cleanedGroupedFields = Object.keys(groupedFields).reduce(
      (acc, groupName) => {
        const groupFields = groupedFields[groupName].filter(
          (field) => !fieldsThatChangeValuesToOtherFields.has(field.fieldName)
        )
        if (groupFields.length > 0) {
          acc[groupName] = groupFields
        }
        return acc
      },
      {}
    )

    return (
      <div className={`${componentClassName}_fieldset-wrapper`}>
        {cleanedUngroupedFields.map((fieldProps, i) => (
          <Fieldset
            tabIndex={0}
            currentStep={currentStep}
            className={`${componentClassName}_fieldset`}
            key={i}
            fieldProps={fieldProps}
            errors={errors}
            disableQueryState={true}
            useFormContextIfAvailable={true}
          />
        ))}
        {Object.keys(groupedFields).map((groupName) => (
          <div
            className={`${componentClassName}_group-wrapper`}
            key={groupName}
          >
            {groupsLabel[groupName] ? (
              <label
                className={`${componentClassName}_group-label`}
                htmlFor={groupName}
              >
                {groupsLabel[groupName]}
              </label>
            ) : null}
            <div
              className={`group-${groupName}`}
              key={groupName}
            >
              {groupedFields[groupName].map((fieldProps, i) => {
                return (
                  <Fieldset
                    tabIndex={0}
                    currentStep={currentStep}
                    className={`${componentClassName}_fieldset`}
                    key={i}
                    fieldProps={fieldProps}
                    errors={errors}
                    disableQueryState={true}
                    useFormContextIfAvailable={true}
                  />
                )
              })}
            </div>
          </div>
        ))}
      </div>
    )
  }
  return null
}

function renderStepForm(stateReducer, formMethods, formFields) {
  const { state, dispatch } = stateReducer
  const { currentStep, isEditMode } = state
  const {
    formState: { errors },
  } = formMethods
  if (isEditMode) {
    return
  }
  if (currentStep > 0) {
    const groupedFields = {}
    const ungroupedFields = []
    const groupsLabel = {}
    const fieldsGroupedByStep = groupFieldsByStep(formFields)
    if (
      typeof fieldsGroupedByStep[currentStep] === 'undefined' ||
      fieldsGroupedByStep[currentStep].length === 0
    ) {
      return <span>Something went wrong!</span>
    }
    const allWatchedFields = getAllWatchedFields(formFields)
    const fields = fieldsGroupedByStep[currentStep]

    fields.forEach((field) => {
      const group = field.uiOptions?.group
      const groupLabel = field.uiOptions?.groupLabel ?? false
      if (group) {
        if (!groupedFields[group]) {
          groupedFields[group] = []
        }
        if (!groupsLabel[group] && groupLabel) {
          groupsLabel[group] = groupLabel
        }
        groupedFields[group].push(field)
      } else {
        ungroupedFields.push(field)
      }
    })

    return (
      <div className={`${componentClassName}_fieldset-wrapper`}>
        {ungroupedFields.map((fieldProps, i) => (
          <Fieldset
            tabIndex={0}
            currentStep={currentStep}
            className={`${componentClassName}_fieldset`}
            key={`fieldset_${fieldProps.name}_${i}`}
            fieldProps={fieldProps}
            errors={errors}
            isWatched={allWatchedFields.includes(fieldProps.fieldName)}
            useFormContextIfAvailable={true}
          />
        ))}
        {Object.keys(groupedFields).map((groupName) => (
          <div
            className={`${componentClassName}_group-wrapper`}
            key={groupName}
          >
            {groupsLabel[groupName] ? (
              <label
                className={`${componentClassName}_group-label`}
                htmlFor={groupName}
              >
                {groupsLabel[groupName]}
              </label>
            ) : null}
            <div
              className={`group-${groupName}`}
              key={groupName}
            >
              {groupedFields[groupName].map((fieldProps, i) => {
                return (
                  <Fieldset
                    tabIndex={0}
                    currentStep={currentStep}
                    className={`${componentClassName}_fieldset`}
                    key={'group-' + i + fieldProps.fieldName}
                    fieldProps={fieldProps}
                    errors={errors}
                    isWatched={allWatchedFields.includes(fieldProps.fieldName)}
                    useFormContextIfAvailable={true}
                  />
                )
              })}
            </div>
          </div>
        ))}
      </div>
    )
  }
  return null
}

function renderSubmitWrapper(
  stateReducer,
  formMethods,
  formFields,
  handlers,
  labels
) {
  const { state, dispatch } = stateReducer
  const {
    currentStep,
    isEditMode,
    recapAccordionState,
    forceCloseRecap,
    selectedEditGroup,
    recapTopLabel,
    isCurrentStepPayment,
    isCurrentStepRecurrencePayment,
    isButtonDisabled,
  } = state

  const {
    handleNextStep,
    handlePreviousStep,
    handleEditForm,
    handleConfirmEdit,
    handleBackFromSelectorEdit,
    handleBackFromEditFields,
    handleSaveEdit,
    handleCompleteRecurrenceDonation,
  } = handlers

  const {
    formState: { errors },
    getValues,
  } = formMethods

  const isFirstStep = currentStep === 1

  const submitWrapperClass = [`${componentClassName}_submit-wrapper`]
  const buttonsWrapperClass = [`${componentClassName}_button-wrapper`]
  if (isFirstStep) buttonsWrapperClass.push('first')

  if (isCurrentStepPayment || isCurrentStepRecurrencePayment) {
    buttonsWrapperClass.push(
      recapAccordionState === false ? 'recap-closed' : 'recap-open'
    )
  }

  if (isEditMode && selectedEditGroup === null) {
    buttonsWrapperClass.push('edit-mode-select')
  }

  if (isEditMode && selectedEditGroup !== null) {
    submitWrapperClass.push('edit-mode-fields')
    buttonsWrapperClass.push('edit-mode-fields')
  } else {
    submitWrapperClass.push(`step-${currentStep}`)
  }

  const buttonsWrapperClassName = buttonsWrapperClass.join(' ')
  const submitWrapperClassName = submitWrapperClass.join(' ')
  const hasPaymentMethod = Boolean(getValues(DONATOR_PAYMENT_METHOD) ?? false)
  const donationAmountValue = getValues(DONATION_AMOUNT)
  const isCreditCardMethod =
    hasPaymentMethod && getValues(DONATOR_PAYMENT_METHOD) === 'creditcard'

  const showPreviousButton =
    !isFirstStep &&
    recapAccordionState === false &&
    !isEditMode &&
    !isCreditCardMethod
  const showFieldsPageButtons = isEditMode && selectedEditGroup !== null
  const showBackFromSelectorEditButton =
    isEditMode && showFieldsPageButtons === false
  const showEditButton =
    recapAccordionState !== false && !isEditMode && forceCloseRecap !== true
  const showNextButton =
    recapAccordionState === false &&
    !isEditMode &&
    !isCurrentStepRecurrencePayment &&
    !isCurrentStepPayment
  const edits = getFieldsEdits(getValues(), formFields)
  const recurrencePaymentMethod = getValues(
    DONATION_PAYMENT_METHOD_ON_RECURRENCE
  )

  return (
    <div className={submitWrapperClassName}>
      <div className={buttonsWrapperClassName}>
        {showPreviousButton ? (
          <button
            className={`${componentClassName}_button-back`}
            type='button'
            onClick={handlePreviousStep}
          >
            {isEditMode ||
            isCurrentStepRecurrencePayment ||
            isCurrentStepPayment ? (
              labels.goBack
            ) : (
              <IoArrowBackOutline />
            )}
          </button>
        ) : null}
        {showBackFromSelectorEditButton ? (
          <button
            className={`${componentClassName}_button-back`}
            type='button'
            onClick={handleBackFromSelectorEdit}
            aria-label={labels.goBack}
          >
            {isEditMode ? labels.goBack : <IoArrowBackOutline />}
          </button>
        ) : null}
        {showEditButton ? (
          <>
            <button
              className={`${componentClassName}_button-edit`}
              type='button'
              onClick={handleEditForm}
            >
              {labels.edit}
            </button>
            <button
              className={`${componentClassName}_button-confirm`}
              type='button'
              onClick={
                isCurrentStepRecurrencePayment
                  ? handleCompleteRecurrenceDonation
                  : handleConfirmEdit
              }
              disabled={isButtonDisabled}
            >
              {isCurrentStepRecurrencePayment
                ? labels.endRecurrenceDonation
                : labels.confirm}
            </button>
          </>
        ) : null}
        {showFieldsPageButtons ? (
          <>
            <Accordion
              isUnstyled={true}
              openDirection={'up'}
              isModalBehavior={false}
              className={`${componentClassName}_recap-accordion`}
              items={[getShowCurrentEditAccordion(edits)]}
            />
            <button
              className={`${componentClassName}_button-edit`}
              type='button'
              onClick={handleBackFromEditFields}
            >
              {labels.cancel}
            </button>
            <button
              className={`${componentClassName}_button-confirm`}
              type='button'
              onClick={handleSaveEdit}
              disabled={edits.editNumber < 1 || isButtonDisabled}
            >
              {labels.saveEdit}
            </button>
          </>
        ) : null}
        {showNextButton ? (
          <button
            className={`${componentClassName}_button-next`}
            tabIndex={0}
            type='button'
            onClick={handleNextStep}
            disabled={
              isButtonDisabled ||
              donationAmountValue ===
                `${KNOWN_FORM_VALUES.CUSTOM_DONATION_AMOUNT}-0` ||
              false
            }
          >
            {labels.goNext}
          </button>
        ) : null}
      </div>
    </div>
  )
}

function renderErrorModal(
  modalErrorIsOpen,
  setModalErrorIsOpen,
  recursivePaymentState,
  setRecursivePaymentState
) {
  return (
    <ErrorModal
      isOpen={
        modalErrorIsOpen === PAYMENT_STATES.WAITING_FOR_PAYMENT ||
        modalErrorIsOpen === PAYMENT_STATES.PAYMENT_ERROR
      }
      isErrror={modalErrorIsOpen === PAYMENT_STATES.PAYMENT_ERROR}
      isLoading={modalErrorIsOpen === PAYMENT_STATES.WAITING_FOR_PAYMENT}
      onClose={() => {
        setModalErrorIsOpen(false)
        setRecursivePaymentState({
          ...recursivePaymentState,
          state: PAYMENT_STATES.IDLE,
          method: '',
        })
      }}
      copy={{
        title:
          modalErrorIsOpen === PAYMENT_STATES.PAYMENT_ERROR
            ? 'Ops qualcosa è andato storto'
            : '',
        body:
          modalErrorIsOpen === PAYMENT_STATES.PAYMENT_ERROR
            ? 'Si è verificato un errore durante il pagamento, ritenta.'
            : 'Stiamo elaborando il pagamento, attendi...',
        cta: 'Chiudi',
      }}
    />
  )
}

export default Form
