/**
 * Custom hook for handling payment operations and state management
 * @param {Object} props - Hook properties
 * @param {Object} props.dataToSend - Data object containing payment and donor information
 * @returns {Object} Payment handler object containing:
 * @returns {string} token - Payment authentication token
 * @returns {Object} paymentOptions - Available payment options configuration
 * @returns {Object} paypalInitialOptions - Initial PayPal configuration options
 * @returns {string} paymentClientId - Payment client ID
 * @returns {string} paypalClientId - PayPal client ID
 * @returns {string} approvalLink - Payment approval link
 * @returns {Function} setApprovalLink - Function to set the approval link
 * @returns {Function} handleOnCreateOrder - Function to create a new payment order
 * @returns {Function} handleOnCreateAlternativeOrder - Function to create alternative payment methods order
 * @returns {Function} handleOnApprove - Function to handle payment approval
 * @returns {Function} handleOnError - Function to handle payment errors
 * @returns {Object} paymentState - Current payment state
 * @returns {Function} setPaymentState - Function to update payment state
 * @returns {boolean} modalErrorIsOpen - Error modal visibility state
 * @returns {Function} setModalErrorIsOpen - Function to toggle error modal
 *
 * @example
 * const {
 *  token,
 *  paymentOptions,
 *  paypalInitialOptions,
 *  paymentClientId,
 *  paypalClientId,
 *  approvalLink,
 *  setApprovalLink,
 *  handleOnCreateOrder,
 *  handleOnCreateAlternativeOrder,
 *  handleOnApprove,
 *  handleOnError,
 *  paymentState,
 *  setPaymentState,
 *  modalErrorIsOpen,
 *  setModalErrorIsOpen,
 * } = usePaymentHandler({
 *  dataToSend: { ...getValues() },
 * })
 */
import { usePayStore } from '@/app/(dona)/layout'
import {
  FIELDNAMES,
  KNOWN_FORM_VALUES,
} from '@/components/molecules/Form/utils/constants'
import { PAYMENT_STATES, SINGLE_PAYMENT_STATUS_KEY } from '@/utils/constants'
import { useLocalStorageState } from 'ahooks'
import { useRouter } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import axiosInstance from '../utils/axiosWrapper'
import { SERVER_PAYMENTS_METHODS } from '../utils/types'

const {
  DONATION_TYPE,
  DONATION_AMOUNT,
  DONATION_FREQUENCY,
  DONATION_IN_MEMORY_RECEIVER_NAME,
  DONATION_IN_MEMORY_LETTER_TO_RELATIVES,
  DONATION_IN_MEMORY_DATE,
  DONATOR_TYPE,
  DONATOR_FIRST_NAME,
  DONATOR_LAST_NAME,
  DONATOR_GENDER,
  DONATOR_EMAIL,
  DONATOR_TAX_CODE,
  DONATOR_CELLPHONE,
  DONATOR_COMPANY_NAME,
  DONATOR_COMPANY_TAX_CODE,
  DONATOR_COMPANY_EMAIL,
  DONATOR_COMPANY_TELEPHONE,
  DONATOR_COMPANY_CELLPHONE,
  DONATOR_ADDRESS,
  DONATOR_ADDRESS_NUMBER,
  DONATOR_COUNTRY,
  DONATOR_POSTAL_CODE,
  DONATOR_CITY,
  DONATOR_PROVINCE,
  DONATION_PAYMENT_METHOD_ON_RECURRENCE,
  DONATION_PAYMENT_METHOD_ON_RECURRENCE_IBAN,
  DONATION_PAYMENT_METHOD_ON_RECURRENCE_IBAN_IS_OWNER,
  DONATION_IBAN_OWNER_FIRST_NAME,
  DONATION_IBAN_OWNER_LAST_NAME,
  DONATION_IBAN_OWNER_EMAIL,
  DONATION_IBAN_OWNER_TAX_CODE,
  DONATOR_LETTER_FORMAT,
  DONATOR_PAYMENT_METHOD,
  DONATION_LETTER_PARTICIPANTS,
  DONATION_LETTER_RECIPIENT_COURTESY_TITLE,
  DONATION_LETTER_RECIPIENT_NAME,
  DONATION_LETTER_RECIPIENT_EMAIL,
  DONATION_PRIVACY,
  DONATION_TERMS,
  DONATION_NEWSLETTER,
  DONATION_LETTER_RECIPIENT_ADDRESS,
  DONATION_LETTER_RECIPIENT_ADDRESS_NUMBER,
  DONATION_LETTER_RECIPIENT_POSTAL_CODE,
  DONATION_LETTER_RECIPIENT_CITY,
  DONATION_LETTER_RECIPIENT_PROVINCE,
  DONATION_LETTER_RECIPIENT_COUNTRY,
} = FIELDNAMES
const {
  FREE_DONATION_TYPE,
  IN_MEMORY_DONATION_TYPE,
  CUSTOM_DONATION_AMOUNT,
  ONE_TIME_DONATION_FREQUENCY,
  MONTHLY_DONATION_FREQUENCY,
  YEARLY_DONATION_FREQUENCY,
  YES,
  NO,
  INDIVIDUAL,
  COMPANY,
  MALE,
  FEMALE,
  CARD,
  IBAN,
  DIGITAL,
  PAPER,
  TRUE,
  FALSE,
  DEFAULT,
} = KNOWN_FORM_VALUES

const usePaymentHandler = ({ dataToSend }) => {
  const { store, setStore } = usePayStore()
  const orderRef = useRef(null)
  const [token, setToken] = useState(null)
  const [paymentOptions, setPaymentOptions] = useState(null)
  const [data, setData] = useState(dataToSend)
  const [approvalLink, setApprovalLink] = useState('')
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false)
  const [paymentState, setPaymentState] = useLocalStorageState(
    SINGLE_PAYMENT_STATUS_KEY,
    {
      listenStorageChange: true,
      defaultValue: {
        state: PAYMENT_STATES.IDLE,
        info: {},
      },
    }
  )
  const router = useRouter()
  const hasRendered = useRef(false)

  const paymentClientId = process.env.paymentClientId
  const paypalClientId = process.env.paypalClientId

  const paypalInitialOptions = {
    'client-id': paypalClientId,
    currency: 'EUR',
    intent: 'capture',
    'data-client-token': token,
    dataClientToken: token,
    components: ['hosted-fields', 'buttons', 'marks', 'funding-eligibility'],
    'disable-funding': 'mybank',
  }

  const fetchPaymentData = async () => {
    try {
      const paypalToken = await fetchPaymentsToken()
      const paymentsOptions = await fetchOptionsPayments()

      setToken(paypalToken)
      setPaymentOptions(paymentsOptions)
    } catch (error) {
      console.error('Error fetchPaymentData:', error)
      return error
    }
    return
  }

  const _handleOrderRequest = async ({ paymentSource }) => {
    const serverPaymentMethod =
      paymentSource === 'creditcard' ? 'creditcard-2022' : paymentSource
    const _dataToSend = formatDataToSend(
      dataToSend,
      paymentClientId,
      serverPaymentMethod
    )
    try {
      const response = await axiosInstance.post(
        '/api/payments/pay',
        _dataToSend
      )

      const {
        data: {
          data: { id_order, ckey, token, approval_link, ...rest },
        },
      } = response

      return {
        success: true,
        orderID: id_order,
        ckey,
        token,
        approvalLink: approval_link,
        ...rest,
      }
    } catch (error) {
      console.error('Error making payment request:', error)
      notifyOnPaymentError(error)
      return {
        success: false,
        error,
      }
    }
  }

  const notifyOnPaymentError = (error) => {
    toast.error(
      'Errore durante il pagamento, ritenta',
      {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      },
      { delay: 500 }
    )
  }

  const _handleConfirm = async ({ ckey, toke, ...rest }) => {
    // could handle redirect here
    return {
      success: true,
      ...rest,
    }
  }

  const handleOnCreateOrder = async (data) => {
    const { paymentSource, returnLink = false } = data
    const method = paymentSource ? paymentSource : 'creditcard'
    const orderResponse = await _handleOrderRequest({ paymentSource: method })
    if (!orderResponse.success) {
      return orderResponse
    }
    orderRef.current = orderResponse
    const { orderID, ckey, token, approvalLink, ...rest } = orderResponse

    setPaymentState({
      state: PAYMENT_STATES.WAITING_FOR_PAYMENT,
      method,
      info: {
        ...orderResponse,
      },
    })

    if (returnLink) {
      return Promise.resolve({ approvalLink })
    }
    return orderResponse
  }

  const handleOnCreateAlternativeOrder = async (paymentSource) => {
    const orderResponse = await _handleOrderRequest({
      paymentSource,
      returnLink: true,
    })
    if (!orderResponse.success) {
      return orderResponse
    }
    const { approvalLink } = orderResponse
    setApprovalLink(approvalLink ? approvalLink : '')
    const windowInstace = () => window.open(approvalLink, '_blank')
    setTimeout(() => {
      windowInstace()
    })
    setPaymentState({
      state: PAYMENT_STATES.WAITING_FOR_PAYMENT,
      method: paymentSource,
      info: {
        ...orderResponse,
      },
    })
    orderRef.current = orderResponse
    return orderResponse
  }

  const handleOnApprove = async ({ ..._data }) => {
    const data = { ..._data, ...orderRef.current }
    const { orderID, authenticationStatus, paymentSource } = data
    const isCreditCardApproved = authenticationStatus === 'APPROVED'
    const isCreditCardDeclined =
      typeof authenticationStatus === 'string' &&
      authenticationStatus !== '' &&
      authenticationStatus !== 'APPROVED' &&
      authenticationStatus !== undefined &&
      authenticationStatus !== null
    const isPaypalApproved = orderID && paymentSource === 'paypal'
    const { ckey, token, ...rest } = data
    const confirmLink = `/dona/successo?ckey=${ckey}&token=${token}`
    const errorLink = `/dona/errore`
    const windowInstace = (link) => window.open(link, '_self')

    if (isPaypalApproved) {
      if (!ckey || !token) {
        windowInstace(errorLink)
        return data
      }
      windowInstace(confirmLink)
    }
    if (isCreditCardApproved) {
      if (!ckey || !token) {
        windowInstace(errorLink)
        return data
      }
      windowInstace(confirmLink)
    }
    if (isCreditCardDeclined) {
      windowInstace(errorLink)
    }
    return data
  }

  const handleOnError = async (error) => {
    // handle error
    console.error('Error:', error)
    // router.push('/dona/errore')
    notifyOnPaymentError(error)
    return error
  }

  useEffect(() => {
    if (!hasRendered.current && paypalClientId && (!token || !paymentOptions)) {
      fetchPaymentData()
      setPaymentState({
        state: PAYMENT_STATES.IDLE,
        method: '',
        info: {},
      })

      hasRendered.current = true
    }
  }, [paymentOptions, paypalClientId, setPaymentState, token])

  useEffect(() => {
    if (paymentState.state === PAYMENT_STATES.PAYMENT_SUCCESS) {
      setModalErrorIsOpen(false)
      setPaymentState({
        state: PAYMENT_STATES.IDLE,
        method: '',
        info: {},
      })
      setStore({
        ...store,
        isSingleDonation: true,
      })
      router.push('/dona/successo')
    }
    if (paymentState.state === PAYMENT_STATES.PAYMENT_ERROR) {
      setModalErrorIsOpen(PAYMENT_STATES.PAYMENT_ERROR)
      setPaymentState({
        state: PAYMENT_STATES.IDLE,
        method: '',
        info: {},
      })
    }
    if (paymentState.state === PAYMENT_STATES.WAITING_FOR_PAYMENT) {
      const timeout = setTimeout(() => {
        setPaymentState({
          state: PAYMENT_STATES.IDLE,
          method: '',
          info: {},
        })
      }, 180000)
      if (modalErrorIsOpen !== PAYMENT_STATES.END_WAITING_FOR_PAYMENT) {
        setModalErrorIsOpen(PAYMENT_STATES.WAITING_FOR_PAYMENT)
      } else {
        setPaymentState({
          state: PAYMENT_STATES.IDLE,
          method: '',
          info: {},
        })
        setModalErrorIsOpen(false)
      }
      return () => clearTimeout(timeout)
    }
  }, [modalErrorIsOpen, paymentState, router, setPaymentState])

  return {
    token,
    paymentOptions,
    paypalInitialOptions,
    paymentClientId,
    paypalClientId,
    approvalLink,
    setApprovalLink,
    handleOnCreateOrder,
    handleOnCreateAlternativeOrder,
    handleOnApprove,
    handleOnError,
    paymentState,
    setPaymentState,
    modalErrorIsOpen,
    setModalErrorIsOpen,
  }
}

async function fetchOptionsPayments() {
  try {
    const response = await axiosInstance.get('/api/options/payments')
    const options = response.data.data ?? {}
    const isLegacyDonation = options.donation === 'old'
    const excludedPaymentMethods = []
    const hasDisclaimer =
      options.disclaimer !== '' &&
      options.disclaimer !== null &&
      options.disclaimer !== undefined
    if (isLegacyDonation) {
      console.error('[❌ Error: Deprecated Donation is not supported yet]')
    }
    const propsToCheck = [
      'creditcard-activation',
      'paypal-activation',
      'postepay-activation',
      'mybank-activation',
      'satispay-activation',
    ]
    propsToCheck.forEach((prop) => {
      if (options[prop] && options[prop] === 'false') {
        excludedPaymentMethods.push(prop)
      }
    })
    return {
      ...options,
      isLegacyDonation,
      hasDisclaimer,
      excludedPaymentMethods,
    }
  } catch (error) {
    console.error('Error fetching payment options:', error)
    return {}
  }
}

async function fetchPaymentsToken() {
  try {
    const response = await axiosInstance.get('/api/payments/token')
    const clientToken = response.data.client_token ?? ''
    return clientToken
  } catch (error) {
    console.error('Error fetching payment token:', error)
    return ''
  }
}

function formatDataToSend(inputData, paymentClientId, serverPaymentMethod) {
  const data = { ...inputData, donatorPaymentMethod: serverPaymentMethod }

  const fieldNameMap = {
    [DONATION_TYPE]: 'donationtype',
    [DONATION_FREQUENCY]: 'frequency',
    [DONATION_AMOUNT]: 'amount',
    [DONATION_IN_MEMORY_RECEIVER_NAME]: 'deceased',
    [DONATION_IN_MEMORY_LETTER_TO_RELATIVES]: 'sendletter',
    [DONATOR_TYPE]: 'usertype',
    [DONATOR_FIRST_NAME]: 'firstname',
    [DONATOR_LAST_NAME]: 'lastname',
    [DONATOR_GENDER]: 'gender',
    [DONATOR_EMAIL]: 'email',
    [DONATOR_TAX_CODE]: 'fiscalcode',
    [DONATOR_CELLPHONE]: 'mobilephone',
    [DONATOR_COMPANY_NAME]: 'companyname',
    [DONATOR_COMPANY_TAX_CODE]: 'taxid',
    [DONATOR_COMPANY_EMAIL]: 'companyemail',
    [DONATOR_COMPANY_TELEPHONE]: 'phone',
    [DONATOR_ADDRESS]: 'billingaddress[address]',
    [DONATOR_ADDRESS_NUMBER]: 'billingaddress[number]',
    [DONATOR_COUNTRY]: 'billingaddress[country]',
    [DONATOR_PROVINCE]: 'billingaddress[state]',
    [DONATOR_CITY]: 'billingaddress[city]',
    [DONATOR_POSTAL_CODE]: 'billingaddress[postalcode]',
    [DONATION_PAYMENT_METHOD_ON_RECURRENCE]: 'paymentContinuslyType',
    [DONATION_PAYMENT_METHOD_ON_RECURRENCE_IBAN]: 'bonificIban',
    [DONATION_PAYMENT_METHOD_ON_RECURRENCE_IBAN_IS_OWNER]: 'bonificAccount',
    [DONATION_IBAN_OWNER_FIRST_NAME]: 'bonificFirstname',
    [DONATION_IBAN_OWNER_LAST_NAME]: 'bonificLastname',
    [DONATION_IBAN_OWNER_TAX_CODE]: 'bonificFiscalCode',
    [DONATOR_LETTER_FORMAT]: 'letter[type]',
    [DONATOR_PAYMENT_METHOD]: 'payment-method',
    [DONATION_LETTER_PARTICIPANTS]: 'letter[senders]',
    [DONATION_LETTER_RECIPIENT_COURTESY_TITLE]: 'letter[title]',
    [DONATION_LETTER_RECIPIENT_NAME]: 'letter[lastname]',
    [DONATION_LETTER_RECIPIENT_EMAIL]: 'letter[recipientemail]',
    [DONATION_PRIVACY]: 'privacy',
    [DONATION_TERMS]: 'termsandconditions',
    [DONATION_NEWSLETTER]: 'newsletter',
    [DONATION_LETTER_RECIPIENT_ADDRESS]: 'letter[recipientaddress][address]',
    [DONATION_LETTER_RECIPIENT_ADDRESS_NUMBER]:
      'letter[recipientaddress][number]',
    [DONATION_LETTER_RECIPIENT_POSTAL_CODE]:
      'letter[recipientaddress][postalcode]',
    [DONATION_LETTER_RECIPIENT_CITY]: 'letter[recipientaddress][city]',
    [DONATION_LETTER_RECIPIENT_PROVINCE]: 'letter[recipientaddress][state]',
    [DONATION_LETTER_RECIPIENT_COUNTRY]: 'letter[recipientaddress][country]',
    paymentClientId: 'client_id',
    // '*': 'fixedAmount',
    // '*': 'amountType',
    // '*': 'anniversary',
    // '*": 'billingaddress[hamlet]', ?  frazione"
    // '*': 'birthdate', ?  data di nascita
    // id_campagna_competenza: campainParams.ets_cmmk || null,
    // id_segmento: campainParams.ets_sgmt || null,
    // id_soggetto: campainParams.ets_sggt || null,
    // id_tipo_soggetto: campainParams.ets_sggt_t || null,
    // id_mezzo: idMezzo
    // check fiscal code e taxid
  }

  const fieldValuesMap = {
    [DONATION_TYPE]: {
      [FREE_DONATION_TYPE]: 'free',
      [IN_MEMORY_DONATION_TYPE]: 'memory',
    },
    [DONATION_AMOUNT]: {
      10: '10',
      20: '20',
      50: '50',
      [CUSTOM_DONATION_AMOUNT]: (value) => value.split('-')[2],
    },
    [DONATION_FREQUENCY]: {
      [ONE_TIME_DONATION_FREQUENCY]: 'singola',
      [MONTHLY_DONATION_FREQUENCY]: 'continuativa',
      [YEARLY_DONATION_FREQUENCY]: 'continuativa',
    },
    [DONATION_IN_MEMORY_LETTER_TO_RELATIVES]: {
      [YES]: '1',
      [NO]: '0',
    },
    [DONATOR_TYPE]: {
      [INDIVIDUAL]: 'persona',
      [COMPANY]: 'azienda',
    },
    [DONATOR_GENDER]: {
      [DEFAULT]: MALE,
      [MALE]: MALE,
      [FEMALE]: FEMALE,
    },
    [DONATOR_PAYMENT_METHOD]: {
      satispay: SERVER_PAYMENTS_METHODS.SATISPAY,
      postepay: SERVER_PAYMENTS_METHODS.POSTEPAY,
      paypal: SERVER_PAYMENTS_METHODS.PAYPAL,
      'credit-card': SERVER_PAYMENTS_METHODS.CREDITCARD,
    },
    [DONATION_PRIVACY]: {
      [TRUE]: '1',
      [FALSE]: '0',
    },
    [DONATION_TERMS]: {
      [TRUE]: '1',
      [FALSE]: '0',
    },
    [DONATION_NEWSLETTER]: {
      [TRUE]: '1',
      [FALSE]: '0',
    },
    [DONATION_LETTER_PARTICIPANTS]: (partecipants) => {
      return partecipants.map((p) => p.value)
    },
    [DONATOR_LETTER_FORMAT]: {
      [DIGITAL]: 'digital',
      [PAPER]: 'paper',
    },
  }

  const finalData = {
    client_id: paymentClientId,
    vertical: 'donation',
  }

  const isCompany = data[DONATOR_TYPE] === 'company' ?? false

  const createNestedObject = (obj, path, value) => {
    if (path.length === 1) {
      obj[path[0]] = value
      return
    }
    if (!obj[path[0]]) {
      obj[path[0]] = {}
    }
    createNestedObject(obj[path[0]], path.slice(1), value)
  }

  for (const [key, value] of Object.entries(data)) {
    const finalKey = fieldNameMap[key] || key
    const finalValue = mapValue(key, value, fieldValuesMap)

    if (finalKey.includes('[') && finalKey.includes(']')) {
      const path = finalKey.replace(/\[/g, '.').replace(/\]/g, '').split('.')
      createNestedObject(finalData, path, finalValue)
    } else {
      finalData[finalKey] = finalValue
    }
  }
  for (const [key, value] of Object.entries(finalData)) {
    if (key === 'fiscalcode' && value !== '') {
      finalData['taxid'] = value
    }
    if (value === '') {
      delete finalData[key]
    }
    if (key === 'companyemail' && isCompany) {
      finalData.email = value
      delete finalData[key]
    }
    if (key === 'letter') {
      if (!value['firstname']) {
        finalData['letter']['firstname'] = ''
      }
      if (value['senders'].length === 1 && value['senders'][0] === 'MAP_NAME') {
        finalData[key]['senders'] = [
          isCompany
            ? `${finalData['companyname']}`
            : `${finalData['firstname']} ${finalData['lastname']}`,
        ]
      }
    }
  }

  return finalData
}

function mapValue(key, value, fieldValuesMap) {
  const valueMapping = fieldValuesMap[key]

  if (valueMapping !== undefined) {
    if (
      key === DONATION_AMOUNT &&
      value?.startsWith(`${CUSTOM_DONATION_AMOUNT}-`)
    ) {
      return valueMapping[CUSTOM_DONATION_AMOUNT](value)
    } else if (typeof valueMapping === 'function') {
      if (key === DONATION_LETTER_PARTICIPANTS) {
        const partecipants = valueMapping(value)
        if (partecipants.length === 0 || !partecipants) {
          return ['MAP_NAME']
        }
      }
      return valueMapping(value)
    } else if (valueMapping[value] !== undefined) {
      return valueMapping[value]
    }
  }
  return value
}

export default usePaymentHandler
