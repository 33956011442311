'use client'

import { AnimatePresence, motion } from 'framer-motion'

import { Input } from 'components/molecules'
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { INPUT_TYPES } from '../Form/utils/constants'

const valuePicker = (value, defaultValue, formValue, useForm) => {
  const isValidValue = (val) =>
    Array.isArray(val) &&
    val.length > 0 &&
    val.every((item) => item && typeof item.value === 'string')
  if (useForm && isValidValue(formValue)) return formValue
  if (isValidValue(value)) return value
  if (isValidValue(defaultValue)) return defaultValue
  return [{ id: 0, value: '' }]
}

const InputAdder = ({
  maxInputList: _maxInputList = 4,
  name,
  useFormContextIfAvailable = true,
  config,
  defaultValue: _defaultValue = [{ id: 0, value: '' }], // Modifica qui
  disableQueryState = false,
  errorLabel,
  placeholder,
  value = null,
  ...rest
}) => {
  const formContext = useFormContext()
  const formInputListValue = formContext?.getValues(name)
  const [inputList, setInputList] = useState(
    valuePicker(
      value,
      _defaultValue,
      formInputListValue,
      useFormContextIfAvailable
    )
  )

  const nextId = useRef(
    Math.max(...(inputList?.map((input) => input.id) || [0]), 0) + 1
  )
  const nextIdFunc = () => nextId.current++
  const maxInputList = _maxInputList || 2

  const areAllValuesNotEmpty = inputList?.every((input) => input.value !== '')
  const isFieldRequired = config?.required?.value ?? false

  let inputAdderProps = {}
  if (formContext && useFormContextIfAvailable) {
    const { register } = formContext
    inputAdderProps = {
      ...register(name),
    }
  }

  const handleChange = (id, event) => {
    const newValue = event.target.value
    setInputList((prevInputList) => {
      const newInputList = prevInputList.map((input) => {
        if (input.id === id) {
          return { ...input, value: newValue }
        }
        return input
      })

      // Se si sta digitando nell'ultimo input, aggiungere un nuovo input se necessario
      const isLastInput = newInputList[newInputList.length - 1].id === id
      const isNotEmpty = newValue !== ''
      if (isLastInput && isNotEmpty && newInputList.length < maxInputList) {
        newInputList.push({ id: nextId.current++, value: '' })
      }

      return newInputList
    })
  }

  const removeItem = (id) => {
    setInputList((prevInputList) => {
      let newInputList = prevInputList.filter((input) => input.id !== id)
      // Se l'ultimo input non è vuoto e non si è raggiunto il massimo, aggiungere un input vuoto
      if (
        newInputList.length < maxInputList &&
        newInputList[newInputList.length - 1]?.value !== ''
      ) {
        newInputList.push({ id: nextId.current++, value: '' })
      }
      return newInputList.length > 0
        ? newInputList
        : [{ id: nextId.current++, value: '' }]
    })
  }

  useEffect(() => {
    if (!inputList) return

    if (inputList.length === 0) {
      setInputList([{ id: nextId.current++, value: '' }])
    }

    if (inputList.length < maxInputList && areAllValuesNotEmpty) {
      setInputList([...inputList, { id: nextId.current++, value: '' }])
    }

    if (formContext && useFormContextIfAvailable) {
      const trimmedInputList = inputList.filter(
        (input) => input.value.trim() !== ''
      )
      formContext.setValue(name, trimmedInputList)

      if (isFieldRequired && trimmedInputList.length === 0) {
        formContext.setError(name, {
          type: 'required',
          message: 'È necessario inserire almeno un valore',
        })
      } else {
        formContext.clearErrors(name)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areAllValuesNotEmpty,
    inputList,
    maxInputList,
    name,
    isFieldRequired,
    useFormContextIfAvailable,
  ])

  if (!inputList) return null

  return (
    <div
      name={name}
      className='input-adder'
    >
      <div className='input-adder_input-wrap'>
        <AnimatePresence>
          {inputList.map((input, index) => {
            return (
              <motion.div
                key={`${name}-${input.id}`}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
              >
                <Input
                  key={`${name}-${input.id}`}
                  type={INPUT_TYPES.TEXT}
                  placeholder={
                    index === inputList.length - 1
                      ? 'Scrivi per aggiungere'
                      : placeholder
                  }
                  name={`${name}-${input.id}`}
                  value={input?.value || ''}
                  onChange={(e) => handleChange(input.id, e)}
                  useFormContextIfAvailable={false}
                  disableQueryState={true}
                  onCloseIconClick={() => {
                    removeItem(input.id)
                  }}
                  hasCloseIcon={
                    input.value !== '' || index !== inputList.length - 1
                  }
                />
              </motion.div>
            )
          })}
        </AnimatePresence>
      </div>
      {inputList.length === maxInputList && (
        <p className='input-adder_disclaimer'>
          {`${errorLabel} ( ${maxInputList} ) `}
        </p>
      )}
    </div>
  )
}

export default InputAdder
