/**
 * Custom hook to handle the creation of a recurrent payment.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.formData - The form data for the payment.
 *
 * @returns {Object} - The hook's return values.
 * @returns {Function} handleCreateRecurrentPayment - Function to initiate the recurrent payment process.
 * @returns {Object} recursivePaymentState - The current state of the recurrent payment.
 * @returns {Function} setRecursivePaymentState - Function to update the state of the recurrent payment.
 * @returns {boolean} modalErrorIsOpen - State indicating if the modal error is open.
 * @returns {Function} setModalErrorIsOpen - Function to set the state of the modal error.
 *
 * @example
 * const {
 *   handleCreateRecurrentPayment,
 *   recursivePaymentState,
 *   setRecursivePaymentState,
 *   modalErrorIsOpen,
 *   setModalErrorIsOpen,
 * } = useRecursivePaymentHandler({ formData });
 */
import { usePayStore } from '@/app/(dona)/layout'
import { FIELDNAMES } from '@/components/molecules/Form/utils/constants'
import { PAYMENT_STATES, RECURRENT_PAYMENT_STATUS_KEY } from '@/utils/constants'
import { useLocalStorageState } from 'ahooks'
import { useRouter } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { iFrameUrlBuilder } from '../utils/recurrentPayment'

const {
  DONATOR_FIRST_NAME,
  DONATOR_LAST_NAME,
  DONATOR_GENDER,
  DONATOR_EMAIL,
  DONATOR_TAX_CODE,
  DONATOR_COMPANY_NAME,
  DONATOR_COMPANY_TAX_CODE,
  DONATOR_COMPANY_EMAIL,
  DONATION_PAYMENT_METHOD_ON_RECURRENCE,
  DONATION_IBAN_OWNER_FIRST_NAME,
  DONATION_IBAN_OWNER_LAST_NAME,
  DONATION_IBAN_OWNER_EMAIL,
  DONATION_IBAN_OWNER_TAX_CODE,
  DONATOR_PAYMENT_METHOD,
} = FIELDNAMES
const useRecursivePaymentHandler = ({ formData }) => {
  const router = useRouter()
  const orderRef = useRef(null)
  const { store, setStore } = usePayStore()
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false)
  const [recursivePaymentState, setRecursivePaymentState] =
    useLocalStorageState(RECURRENT_PAYMENT_STATUS_KEY, {
      listenStorageChange: true,
      defaultValue: {
        state: PAYMENT_STATES.IDLE,
        info: {},
      },
    })

  const handleCreateRecurrentPayment = async () => {
    try {
      setStore({
        ...store,
        formValues: {
          ...store?.formValues,
          ...formData,
        },
      })
      const iframeUrl = iFrameUrlBuilder(formData)

      // Store payment info in ref for later use
      orderRef.current = {
        formData,
        iframeUrl,
      }

      setRecursivePaymentState({
        state: PAYMENT_STATES.WAITING_FOR_PAYMENT,
        method: 'recurrent',
        info: {
          formData,
          iframeUrl,
        },
      })
      setModalErrorIsOpen(PAYMENT_STATES.WAITING_FOR_PAYMENT)

      // Open iframe URL in new window
      window.open(iframeUrl, '_blank', 'width=600,height=800')

      return {
        success: true,
        iframeUrl,
      }
    } catch (error) {
      console.error('Error creating recurrent payment:', error)
      notifyOnPaymentError(error)
      setModalErrorIsOpen(PAYMENT_STATES.PAYMENT_ERROR)
      return {
        success: false,
        error,
      }
    }
  }

  const notifyOnPaymentError = (error) => {
    toast.error(
      `Errore durante il pagamento ricorrente, ritenta: ${error}`,
      {
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      },
      { delay: 500 }
    )
  }

  useEffect(() => {
    if (recursivePaymentState.state === PAYMENT_STATES.PAYMENT_SUCCESS) {
      setModalErrorIsOpen(PAYMENT_STATES.END_WAITING_FOR_PAYMENT)
      setRecursivePaymentState({
        state: PAYMENT_STATES.IDLE,
        method: '',
        info: {},
      })
      const _formValues = store?.formValues || {}
      const recurrentField = Object.entries(_formValues)
        .filter(([key, value]) => {
          const keyToInlcude = [
            DONATOR_FIRST_NAME,
            DONATOR_LAST_NAME,
            DONATOR_EMAIL,
            DONATOR_TAX_CODE,
            DONATOR_COMPANY_NAME,
            DONATOR_COMPANY_TAX_CODE,
            DONATOR_COMPANY_EMAIL,
            DONATION_IBAN_OWNER_FIRST_NAME,
            DONATION_IBAN_OWNER_LAST_NAME,
            DONATION_IBAN_OWNER_EMAIL,
            DONATION_IBAN_OWNER_TAX_CODE,
          ]
          if (keyToInlcude.includes(key)) {
            return true
          }
          return false
        })
        .reduce((acc, [key, value]) => {
          acc[key] = value
          return acc
        }, {})

      const finalIbanOwnerFirstName =
        recurrentField[DONATION_IBAN_OWNER_FIRST_NAME] ||
        recurrentField[DONATOR_FIRST_NAME] ||
        recurrentField[DONATOR_COMPANY_NAME] ||
        false

      const finalIbanOwnerLastName =
        recurrentField[DONATION_IBAN_OWNER_LAST_NAME] ||
        recurrentField[DONATOR_LAST_NAME] ||
        false

      const finalIbanOwnerTaxCode =
        recurrentField[DONATION_IBAN_OWNER_TAX_CODE] ||
        recurrentField[DONATOR_TAX_CODE] ||
        recurrentField[DONATOR_COMPANY_TAX_CODE] ||
        false

      const finalIbanOwnerEmail =
        recurrentField[DONATION_IBAN_OWNER_EMAIL] ||
        recurrentField[DONATOR_EMAIL] ||
        recurrentField[DONATOR_COMPANY_EMAIL] ||
        false

      setStore({
        ...store,
        isRecurrentPayment: true,
        successBox: {
          ...(finalIbanOwnerFirstName ? { name: finalIbanOwnerFirstName } : {}),
          ...(finalIbanOwnerLastName
            ? { lastname: finalIbanOwnerLastName }
            : {}),
          ...(finalIbanOwnerTaxCode ? { taxcode: finalIbanOwnerTaxCode } : {}),
          ...(finalIbanOwnerEmail ? { email: finalIbanOwnerEmail } : {}),
        },
        recurrentPaymentMethod:
          _formValues[DONATOR_PAYMENT_METHOD] ||
          _formValues[DONATION_PAYMENT_METHOD_ON_RECURRENCE] ||
          '',
        recursivePaymentState,
      })

      const url = `/dona/successo`
      router.push(url)
    }
    if (recursivePaymentState.state === PAYMENT_STATES.PAYMENT_ERROR) {
      setModalErrorIsOpen(PAYMENT_STATES.PAYMENT_ERROR)
      setRecursivePaymentState({
        state: PAYMENT_STATES.IDLE,
        method: '',
        info: {},
      })
      // router.push('/dona/errore')
    }
    if (recursivePaymentState.state === PAYMENT_STATES.WAITING_FOR_PAYMENT) {
      // Reset payment state after timeout
      const timeout = setTimeout(() => {
        setRecursivePaymentState({
          state: PAYMENT_STATES.IDLE,
          method: '',
          info: {},
        })
      }, 180000)
      return () => clearTimeout(timeout)
    }
  }, [recursivePaymentState, router, setRecursivePaymentState])

  return {
    handleCreateRecurrentPayment,
    recursivePaymentState,
    setRecursivePaymentState,
    modalErrorIsOpen,
    setModalErrorIsOpen,
  }
}

export default useRecursivePaymentHandler
