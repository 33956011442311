import { FIELDNAMES } from './utils/constants'
import { getLabelToDisplay, getValueToDisplay } from './utils/functions'

function FormRecapItem({ label, value, oldValue = null, fieldName, ...rest }) {
  if (
    !value ||
    fieldName === FIELDNAMES.DONATOR_PAYMENT_METHOD ||
    fieldName?.startsWith(`${FIELDNAMES.DONATION_LETTER_PARTICIPANTS}-`)
  )
    return null
  const valueToDisplay = getValueToDisplay(value, { ...rest, fieldName })
  const labelToDisplay = getLabelToDisplay(label, { ...rest, fieldName })
  const oldValueToDisplay = getValueToDisplay(oldValue, { ...rest, fieldName })

  return (
    <div
      className='recap_item'
      role='listitem'
      tabIndex='0'
    >
      <span className='recap_item_label'>{labelToDisplay}</span>
      <span className='recap_item_value-wrapper'>
        {oldValue ? (
          <>
            <span className='recap_item_old-value'>{oldValueToDisplay}</span>
            <span className='recap_item_separator'>{'  '}</span>
          </>
        ) : (
          ``
        )}
        <span className='recap_item_value'>{valueToDisplay}</span>
      </span>
    </div>
  )
}

export default FormRecapItem
