import { useFormContext } from 'react-hook-form'
import { IoChevronForwardOutline } from 'react-icons/io5'
import { FIELDNAMES } from './utils/constants'
import { getAllWatchedFields, groupFieldsByEdit } from './utils/functions'

function EditCard({ label, subLabel, name, onClick }) {
  return (
    <div
      className={`edit-card`}
      onClick={onClick}
    >
      <div className='edit-card_text'>
        <h3 className='edit-card_text_title'>{label}</h3>
        <p className='edit-card_text_subtitle'>{subLabel}</p>
      </div>
      <IoChevronForwardOutline className='edit-card_icon' />
    </div>
  )
}
function EditSelector({
  options: { title, subTitle },
  editGroups,
  formFieldsProps,
  onSelectedGroup,
  componentClassName = 'dynamic-form',
  currentFormStep = 0,
  ...rest
}) {
  // const allWatchedFields =
  const mainClass = `show ${componentClassName}_edit-selector`
  const { watch } = useFormContext()

  const fieldsByEdit = groupFieldsByEdit(formFieldsProps, currentFormStep)
  const formState = watch()

  const fieldNamesToExclude = [
    ...getAllWatchedFields(formFieldsProps),
    FIELDNAMES.DONATOR_PAYMENT_METHOD,
  ]

  const showableFieldnames = Object.entries(formState)
    .filter(
      ([field, fieldValue]) =>
        !fieldNamesToExclude.includes(field) && fieldValue
    )
    .map(([field, fieldValue]) => ({
      [field]: fieldValue,
    }))

  const renderEditCards = () => {
    const editCards = Object.entries(editGroups).reduce((acc, [key, group]) => {
      const { id } = group
      const fields = fieldsByEdit[id]

      if (!fields) {
        return acc
      }

      const showableFields = fields.filter((field) => {
        const { fieldName } = field
        return showableFieldnames.some((showableField) =>
          Object.keys(showableField).includes(fieldName)
        )
      })

      if (showableFields.length === 0) {
        return acc
      }

      acc[key] = {
        ...group,
        fields: showableFields,
      }

      return acc
    }, {})
    return Object.entries(editCards).map(([key, group]) => (
      <EditCard
        key={key}
        onClick={() => onSelectedGroup(group.id)}
        {...group}
      />
    ))
  }

  return (
    <div className={mainClass}>
      <div className={`${mainClass}_head`}>
        <h2 className={`${mainClass}_head_title`}>{title ?? ''}</h2>
        <p className={`${mainClass}_head_subtitle`}>{subTitle ?? ''}</p>
      </div>
      <div className={`${mainClass}_cards`}>{renderEditCards()}</div>
    </div>
  )
}

export default EditSelector
