import {
  FIELDNAMES,
  KNOWN_FORM_VALUES,
} from '@/components/molecules/Form/utils/constants'

const basePath = process.env.siteUrl
const inMemoryUrl = process.env.recurrentInMemoryPaymentUrl
const freeDonationUrl = process.env.recurrentPaymentUrl

const {
  DONATION_TYPE,
  DONATION_AMOUNT,
  DONATION_FREQUENCY,
  DONATION_IN_MEMORY_RECEIVER_NAME,
  DONATION_IN_MEMORY_DATE,
  DONATION_IN_MEMORY_LETTER_TO_RELATIVES,
  DONATOR_FIRST_NAME,
  DONATOR_LAST_NAME,
  DONATOR_GENDER,
  DONATOR_EMAIL,
  DONATOR_TAX_CODE,
  DONATOR_CELLPHONE,
  DONATOR_COMPANY_NAME,
  DONATOR_ADDRESS,
  DONATOR_ADDRESS_NUMBER,
  DONATOR_COUNTRY,
  DONATOR_POSTAL_CODE,
  DONATOR_CITY,
  DONATOR_PROVINCE,
  DONATION_PAYMENT_METHOD_ON_RECURRENCE,
  DONATION_PAYMENT_METHOD_ON_RECURRENCE_IBAN,
  DONATION_PAYMENT_METHOD_ON_RECURRENCE_IBAN_IS_OWNER,
  DONATION_IBAN_OWNER_FIRST_NAME,
  DONATION_IBAN_OWNER_LAST_NAME,
  DONATION_IBAN_OWNER_EMAIL,
  DONATION_IBAN_OWNER_TAX_CODE,
  DONATOR_LETTER_FORMAT,
  DONATION_LETTER_PARTICIPANTS,
  DONATION_LETTER_RECIPIENT_NAME,
  DONATION_LETTER_RECIPIENT_COURTESY_TITLE,
  DONATION_LETTER_RECIPIENT_EMAIL,
  DONATION_PRIVACY,
  DONATION_TERMS,
  DONATION_NEWSLETTER,
  DONATION_LETTER_RECIPIENT_ADDRESS,
  DONATION_LETTER_RECIPIENT_ADDRESS_NUMBER,
  DONATION_LETTER_RECIPIENT_POSTAL_CODE,
  DONATION_LETTER_RECIPIENT_CITY,
  DONATION_LETTER_RECIPIENT_PROVINCE,
  DONATION_LETTER_RECIPIENT_COUNTRY,
} = FIELDNAMES
const {
  FREE_DONATION_TYPE,
  IN_MEMORY_DONATION_TYPE,
  MONTHLY_DONATION_FREQUENCY,
  YEARLY_DONATION_FREQUENCY,
  YES,
  NO,
  MALE,
  FEMALE,
  DEFAULT,
  CARD,
  IBAN,
  DIGITAL,
  PAPER,
} = KNOWN_FORM_VALUES

const iframeCodeMapping = {
  [DONATOR_COMPANY_NAME]: {
    legacyKey: 'companyname',
    iframeCode: {
      freeDonation: 1529,
      memoryDonation: 6696,
    },
  },
  [DONATION_PRIVACY]: {
    legacyKey: 'initial_privacy',
    iframeCode: {
      freeDonation: (isChecked) => (isChecked ? `1635=1` : `1635=0`),
      memoryDonation: (isChecked) => (isChecked ? `6697=1` : `6697=0`),
    },
  },
  [DONATION_TERMS]: {
    legacyKey: 'termsandconditions',
    iframeCode: {
      freeDonation: (isChecked) => (isChecked ? `1636=1` : `1636=0`),
      memoryDonation: (isChecked) => (isChecked ? `6698=1` : `6698=0`),
    },
  },
  [DONATION_AMOUNT]: {
    legacyKey: 'amount',
    iframeCode: {
      freeDonation: (value) => `1762=altro&1761=${value}`,
      memoryDonation: (value) => `6700=altro&6699=${value}&amount=${value}`,
    },
  },
  [DONATION_TYPE]: {
    legacyKey: 'amountType',
    iframeCode: {
      freeDonation: (value) =>
        value === IN_MEMORY_DONATION_TYPE
          ? `1763=591&1788=591`
          : `1763=590&1788=590`,
      memoryDonation: (value) =>
        value === IN_MEMORY_DONATION_TYPE
          ? `6701=591&6724=591`
          : `6701=590&6724=590`,
    },
  },
  [DONATION_FREQUENCY]: {
    legacyKey: 'recurrenceType',
    iframeCode: {
      freeDonation: (donationFrequencyValue) => {
        if (donationFrequencyValue === MONTHLY_DONATION_FREQUENCY)
          return `1764=6`
        if (donationFrequencyValue === YEARLY_DONATION_FREQUENCY)
          return `1764=12`
        return `1764=INVALID`
      },
      memoryDonation: (donationFrequencyValue) => {
        if (donationFrequencyValue === MONTHLY_DONATION_FREQUENCY)
          return `6702=1`
        if (donationFrequencyValue === YEARLY_DONATION_FREQUENCY)
          return `6702=12`
        return `6702=INVALID`
      },
    },
  },
  [DONATION_PAYMENT_METHOD_ON_RECURRENCE]: {
    legacyKey: 'paymentContinuslyType',
    iframeCode: {
      freeDonation: (paymentMethod) => {
        if (paymentMethod === CARD) return `1765=Carta_di_credito`
        if (paymentMethod === IBAN) return `1765=Conto_corrente`
        return `1765=INVALID`
      },
      memoryDonation: (paymentMethod) => {
        if (paymentMethod === CARD) return `6703=Carta_di_credito`
        if (paymentMethod === IBAN) return `6703=Conto_corrente`
        return `6703=INVALID`
      },
    },
  },
  [DONATOR_FIRST_NAME]: {
    legacyKey: 'firstname',
    iframeCode: {
      freeDonation: 1766,
      memoryDonation: 6704,
    },
  },
  [DONATOR_LAST_NAME]: {
    legacyKey: 'lastname',
    iframeCode: {
      freeDonation: 1767,
      memoryDonation: 6705,
    },
  },
  [DONATOR_ADDRESS]: {
    legacyKey: 'address',
    iframeCode: {
      freeDonation: 1768,
      memoryDonation: 6706,
    },
  },
  [DONATOR_ADDRESS_NUMBER]: {
    legacyKey: 'number',
    iframeCode: {
      freeDonation: 1769,
      memoryDonation: 6707,
    },
  },
  [DONATOR_POSTAL_CODE]: {
    legacyKey: 'cap',
    iframeCode: {
      freeDonation: 1770,
      memoryDonation: 6708,
    },
  },
  [DONATOR_CITY]: {
    legacyKey: 'city',
    iframeCode: {
      freeDonation: 1771,
      memoryDonation: 6709,
    },
  },
  [DONATOR_PROVINCE]: {
    legacyKey: 'province',
    iframeCode: {
      freeDonation: 1772,
      memoryDonation: 6710,
    },
  },
  [DONATOR_EMAIL]: {
    legacyKey: 'email',
    iframeCode: {
      freeDonation: 1773,
      memoryDonation: 6711,
    },
  },
  [DONATOR_TAX_CODE]: {
    legacyKey: ['fiscalcode', 'taxId'],
    iframeCode: {
      freeDonation: 1774,
      memoryDonation: 6712,
    },
  },
  donatorBirthdate: {
    legacyKey: 'birthdate',
    iframeCode: {
      freeDonation: 1775,
      memoryDonation: 6713,
    },
  },
  [DONATOR_GENDER]: {
    legacyKey: 'sex',
    iframeCode: {
      freeDonation: (gender) => (gender === MALE ? `1776=1` : `1776=2`),
      memoryDonation: (gender) => (gender === MALE ? `6714=1` : `6714=2`),
    },
  },
  [DONATOR_CELLPHONE]: {
    legacyKey: 'cell',
    iframeCode: {
      freeDonation: 1777,
      memoryDonation: 6715,
    },
  },
  [DONATION_IBAN_OWNER_FIRST_NAME]: {
    legacyKey: 'bonificFirstname',
    iframeCode: {
      freeDonation: 1779,
      memoryDonation: 6716,
    },
  },
  [DONATION_IBAN_OWNER_LAST_NAME]: {
    legacyKey: 'bonificLastname',
    iframeCode: {
      freeDonation: 1780,
      memoryDonation: 6717,
    },
  },
  [DONATION_IBAN_OWNER_TAX_CODE]: {
    legacyKey: 'bonificFiscalCode',
    iframeCode: {
      freeDonation: 1774,
      memoryDonation: 6712,
    },
  },
  [DONATION_PAYMENT_METHOD_ON_RECURRENCE_IBAN]: {
    legacyKey: 'bonificIban',
    iframeCode: {
      freeDonation: 1781,
      memoryDonation: 6718,
    },
  },
  [DONATOR_COUNTRY]: {
    legacyKey: 'nation',
    iframeCode: {
      freeDonation: 2690,
      memoryDonation: 6732,
    },
  },
  [DONATION_IN_MEMORY_DATE]: {
    legacyKey: 'anniversary',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: (date) => {
        // date = '2025-12-25'
        if (!date) return ''
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/
        if (!dateRegex.test(date)) return ''
        const month = date.split('-')[1]
        const day = date.split('-')[2]
        return `6764=${day}/${month}`
      },
    },
  },
  [DONATION_LETTER_RECIPIENT_EMAIL]: {
    legacyKey: 'emailtosend',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: 6765,
    },
  },
  [DONATION_LETTER_PARTICIPANTS]: {
    legacyKey: 'partecipant',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: (_, formValues) => {
        const hasPartecipants =
          formValues[DONATION_LETTER_PARTICIPANTS].length > 0 ?? false

        const participants =
          formValues[DONATION_LETTER_PARTICIPANTS]?.map((i) => i.value)?.join(
            ', '
          ) || 'not-group'
        const sender =
          formValues[DONATOR_COMPANY_NAME] ||
          `${formValues[DONATOR_FIRST_NAME]} ${formValues[DONATOR_LAST_NAME]}`

        if (hasPartecipants) {
          return `6769=${participants}&6766=S`
        } else {
          return `6769=${sender}&6766=N`
        }
      },
    },
  },
  [DONATION_IN_MEMORY_RECEIVER_NAME]: {
    legacyKey: 'toRemember',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: 6770,
    },
  },
  [DONATION_LETTER_RECIPIENT_COURTESY_TITLE]: {
    legacyKey: 'recipienttype',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: (recipientType, formValues) => {
        const mustSendLetter =
          formValues[DONATION_IN_MEMORY_LETTER_TO_RELATIVES] === YES ?? false
        if (recipientType && mustSendLetter) {
          switch (recipientType) {
            case 'Famiglia':
              return `6774=40`
            case 'Signor':
              return `6774=1`
            case 'Signora':
              return `6774=25`
          }
        }
        return null
      },
    },
  },
  [DONATION_LETTER_RECIPIENT_NAME]: {
    legacyKey: 'recipienttypesurname',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: 6773,
    },
  },
  [DONATION_LETTER_RECIPIENT_ADDRESS]: {
    legacyKey: 'addresstosend',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: 6776,
    },
  },
  [DONATION_LETTER_RECIPIENT_ADDRESS_NUMBER]: {
    legacyKey: 'numbertosend',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: 6777,
    },
  },
  [DONATION_LETTER_RECIPIENT_POSTAL_CODE]: {
    legacyKey: 'captosend',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: 6778,
    },
  },
  [DONATION_LETTER_RECIPIENT_CITY]: {
    legacyKey: 'citytosend',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: 6780,
    },
  },
  [DONATION_LETTER_RECIPIENT_PROVINCE]: {
    legacyKey: 'provincetosend',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: 6781,
    },
  },
  [DONATION_LETTER_RECIPIENT_COUNTRY]: {
    legacyKey: 'deceasedNation',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: 6782,
    },
  },
  [DONATOR_LETTER_FORMAT]: {
    legacyKey: 'format',
    iframeCode: {
      freeDonation: 0,
      memoryDonation: (letterFormat, formValues) => {
        const mustSendLetter =
          formValues[DONATION_IN_MEMORY_LETTER_TO_RELATIVES] === YES ?? false
        if (letterFormat && mustSendLetter) {
          const formatType = letterFormat === DIGITAL ? 'E' : 'C'
          return `6834=${formatType}`
        }
        return null
      },
    },
  },
}

const iframeIncludes = {
  successUrl: {
    value: `${basePath}/dona/successo`,
    iframeCode: {
      freeDonation: 1793,
      memoryDonation: 6729,
    },
  },
  cancelUrl: {
    value: `${basePath}/dona/errore`,
    iframeCode: {
      freeDonation: 1794,
      memoryDonation: 6730,
    },
  },
  acceptTerms: {
    value: '1',
    iframeCode: {
      freeDonation: 'accettaTermini',
      memoryDonation: 'accettaTermini',
    },
  },
  fhSource: {
    value: (isInMemory) => (isInMemory ? '1619' : `284`),
    iframeCode: {
      freeDonation: 1792,
      memoryDonation: 6728,
    },
  },
  promoter: {
    value: '1011063',
    iframeCode: {
      freeDonation: 1791,
      memoryDonation: 6727,
    },
  },
  finalization1: {
    value: '16903',
    iframeCode: {
      freeDonation: 1789,
      memoryDonation: 6725,
    },
  },
  finalization2: {
    value: '16903',
    iframeCode: {
      freeDonation: 1790,
      memoryDonation: 6726,
    },
  },
}

export const iFrameUrlBuilder = (formValues = {}) => {
  const isInMemory = formValues[DONATION_TYPE] === IN_MEMORY_DONATION_TYPE
  const _baseUrl = isInMemory ? inMemoryUrl : freeDonationUrl

  if (!_baseUrl) {
    console.error('Missing recurrent payment URL:', {
      isInMemory,
      inMemoryUrl,
      freeDonationUrl,
    })
    throw new Error(
      `Missing ${isInMemory ? 'memory' : FREE_DONATION_TYPE} donation URL configuration`
    )
  }

  const [urlBase, existingParams] = _baseUrl.split('?')
  const queryParams = existingParams ? existingParams.split('&') : []

  const iframeDonationTypeKey = isInMemory ? 'memoryDonation' : 'freeDonation'
  for (const [fieldName, codeConfig] of Object.entries(iframeCodeMapping)) {
    const formValue = formValues[fieldName]
    if (formValue === undefined || formValue === '') continue
    const hasDonationIbanOwnerTaxCode =
      formValues[DONATION_IBAN_OWNER_TAX_CODE] !== ''
    const isDonatorTaxCode = fieldName === DONATOR_TAX_CODE
    if (isDonatorTaxCode && hasDonationIbanOwnerTaxCode) {
      continue
    }
    const { iframeCode } = codeConfig
    // Handle special cases where iframeCode is a function
    if (typeof iframeCode[iframeDonationTypeKey] === 'function') {
      const paramString = iframeCode[iframeDonationTypeKey](
        formValue,
        formValues
      )
      queryParams.push(paramString)
      continue
    }

    // Handle standard cases where iframeCode is a number
    queryParams.push(
      `${iframeCode[iframeDonationTypeKey]}=${encodeURIComponent(formValue)}`
    )
  }

  for (const [fieldName, codeConfig] of Object.entries(iframeIncludes)) {
    const formValue = codeConfig.value
    if (formValue === undefined || formValue === '') continue

    const { iframeCode } = codeConfig

    // Handle special cases where iframeCode is a function
    if (typeof codeConfig.value === 'function') {
      const paramString = `${
        codeConfig.iframeCode[iframeDonationTypeKey]
      }=${codeConfig.value(isInMemory)}`
      queryParams.push(paramString)
      continue
    }

    // Handle standard cases where iframeCode is a number
    queryParams.push(
      `${iframeCode[iframeDonationTypeKey]}=${encodeURIComponent(formValue)}`
    )
  }

  // if donationIbanOwnerFirstName , donationIbanOwnerLastName,donationIbanOwnerEmail, donationIbanOwnerTaxCode are empty
  // use donatorFirstName, donatorLastName, donatorTaxCode, donatorEmail , using iframeCodeMapping
  if (
    formValues[DONATION_IBAN_OWNER_FIRST_NAME] === '' &&
    formValues[DONATION_IBAN_OWNER_LAST_NAME] === ''
  ) {
    queryParams.push(
      `${iframeCodeMapping[DONATION_IBAN_OWNER_FIRST_NAME].iframeCode[iframeDonationTypeKey]}=${encodeURIComponent(formValues[DONATOR_FIRST_NAME])}`
    )
    queryParams.push(
      `${iframeCodeMapping[DONATION_IBAN_OWNER_LAST_NAME].iframeCode[iframeDonationTypeKey]}=${encodeURIComponent(formValues[DONATOR_LAST_NAME])}`
    )
  }

  if (formValues[DONATION_IBAN_OWNER_TAX_CODE] === '') {
    queryParams.push(
      `${iframeCodeMapping[DONATION_IBAN_OWNER_TAX_CODE].iframeCode[iframeDonationTypeKey]}=${encodeURIComponent(formValues[DONATOR_TAX_CODE])}`
    )
  }

  // Build the final URL
  return `${urlBase}${queryParams.length ? '?' : ''}${queryParams.join('&')}`
}

const freeDonation = [
  'companyname:1529',
  'initial_privacy:1635',
  'termsandconditions:1636',
  'amount:1762=altro&1761',
  'fixedAmount:1762',
  'amountType:1763',
  'recurrenceType:1764',
  'paymentContinuslyType:1765',
  'firstName:1766',
  'lastName:1767',
  'address:1768',
  'number:1769',
  'cap:1770',
  'city:1771',
  'province:1772',
  'email:1773',
  'fiscalcode:1774',
  'taxId:1774',
  'dob:1775',
  'sex:1776',
  'cell:1777',
  'bonificFirstName:1779',
  'bonificLastName:1780',
  'bonificIban:1781',
  'amountDetails:1782',
  'amountParam:1783',
  'ets_cmmk:1784',
  'ets_sgmt:1785',
  '_ga:1786',
  'donationType:1788',
  'finalization1:1789',
  'finalization2:1790',
  'promoter:1791',
  'fhSource:1792',
  'successUrl:1793',
  'cancelUrl:1794',
  'vat:1795',
  'nation:2690',
  'acceptTerms:accettaTermini',
  'phone:XXXX',
  'ets_sggt:2691',
  'ets_sggt_t:2692',
]
const memoryDonation = [
  'companyname:6696',
  'initial_privacy:6697',
  'termsandconditions:6698',
  'amount:6700=altro&6699',
  'fixedAmount6700',
  'amountType:6701',
  'recurrenceType:6702',
  'paymentContinuslyType:6703',
  'firstName:6704',
  'lastName:6705',
  'address:&6706',
  'number:6707',
  'cap:6708',
  'city:6709',
  'province:6710',
  'email:6711',
  'fiscalcode:6712',
  'taxId:6712',
  'dob:6713',
  'sex:6714',
  'cell:6715',
  'bonificFirstName:6716',
  'bonificLastName:6717',
  'bonificIban:6718',
  'amountDetails:6719',
  'amountParam:6720',
  'ets_cmmk:6721',
  'ets_sgmt:6722',
  '_ga:6723',
  'donationType:6724',
  'finalization1:6725',
  'finalization2:6726',
  'promoter:6727',
  'fhSource:6728',
  'successUrl:6729',
  'cancelUrl:6730',
  'vat:6731',
  'nation:6732',
  'acceptTerms:accettaTermini',
  'anniversary:6764',
  'emailtosend:6765',
  'whosendtheletter:6766',
  'extraTextFlag:',
  'extraText:6768',
  'partecipant:6769',
  'toRemember:6770',
  'recipienttypename:6771',
  'deceasedSex:6772',
  'recipienttypesurname:6773',
  'recipienttype:6774',
  'deceasedDetailsAddress:6775',
  'addresstosend:6776',
  'numbertosend:6777',
  'captosend:6778',
  'deceasedAddressFraction:6779',
  'citytosend:6780',
  'provincetosend:6781',
  'deceasedNation:6782',
  'deceasedExturb:6783',
  'deceasedAddressRef:6784',
  'organizationTypeId:6785',
  'format:6834',
  'phone:6975',
  'ets_sggt:6976',
  'ets_sggt_t:6977',
]
