'use client'

import { Fragment, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  FIELDNAMES,
  INPUT_TYPES,
  KNOWN_FORM_VALUES,
} from '../Form/utils/constants'

const {
  CUSTOM_DONATION_AMOUNT,
  ONE_TIME_DONATION_FREQUENCY,
  IN_MEMORY_DONATION_TYPE,
} = KNOWN_FORM_VALUES
const { RADIO_GROUP } = INPUT_TYPES
const { DONATION_FREQUENCY, DONATION_TYPE } = FIELDNAMES

const RadioGroup = ({
  label = 'Ricorrenza della donazione',
  classNames = {
    radioGroup: 'radio-group',
    radioOption: 'radio-option',
    radioOptionLabel: 'radio-option_label',
    radioOptionSubLabel: 'radio-option_sublabel',
  },
  name = 'donation-period',
  defaultValue: _defaultValue = '',
  radioOptions: _radioOptions = [
    {
      value: 'mensile',
      label: 'Mensile',
      subLabel: '',
      name: 'donation-period',
    },
    {
      value: 'semestrale',
      label: 'Semestrale',
      subLabel: '',
      name: 'donation-period',
    },
    {
      value: 'custom',
      label: 'Annuale',
      subLabel: '',
      name: 'donation-period',
    },
  ],
  radioOptionsByField = false,
  isHidden = false,
  disableQueryState = false,
  value = '',
  useFormContextIfAvailable = false,
  config,
}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState,
  } = useFormContext()

  const [radioOptions, defaultValue] = useFormContextIfAvailable
    ? getRadioOptions(
        _radioOptions,
        radioOptionsByField,
        getValues(),
        _defaultValue,
        value
      )
    : [_radioOptions, _defaultValue]
  const isEditField = name.includes('edit')
  const currentFormValue = getValues(name) || value
  const [selectedRadioValue, setSelectedRadioValue] = useState(
    useFormContextIfAvailable && !isEditField ? currentFormValue : defaultValue
  )
  const [radioInputValue, updateRadioInputValue] = useState(
    selectedRadioValue.startsWith(`${CUSTOM_DONATION_AMOUNT}-`)
      ? selectedRadioValue.split('-')[2]
      : 1
  )

  const hasCustomOptionSelected =
    selectedRadioValue.startsWith('custom') && radioInputValue
  const customOptionType = 'number'

  const currentFormSelection = watch(name) ?? selectedRadioValue
  const { onChange, ref } = register(name)

  const isRadioButtonChecked = (value, index = null) => {
    // Gestione speciale per valori custom
    if (
      selectedRadioValue.startsWith(`${CUSTOM_DONATION_AMOUNT}-`) ||
      currentFormSelection.startsWith(`${CUSTOM_DONATION_AMOUNT}-`)
    ) {
      return value.startsWith(CUSTOM_DONATION_AMOUNT)
    }
    // Se non è un valore custom, verifica se l'opzione corrente è nelle opzioni disponibili
    const currentRadioOptionsHasValue = radioOptions.some(
      (option) => option.value === value
    )
    // Se il valore selezionato o il valore del form non sono nelle opzioni correnti
    const currentRadioOptionsHasSelectedOrCurrentFormValue = radioOptions.some(
      (option) =>
        option.value === selectedRadioValue || option.value === currentFormValue
    )
    if (
      !currentRadioOptionsHasSelectedOrCurrentFormValue &&
      currentRadioOptionsHasValue
    ) {
      return index === 0
    }
    // Se siamo sul valore di default
    if (
      selectedRadioValue === defaultValue ||
      currentFormValue === defaultValue
    ) {
      return value === defaultValue
    }
    // Verifica normale del valore selezionato
    return value === selectedRadioValue || value === currentFormSelection
  }

  const addCustomValue = (value) => {
    if (value?.startsWith(CUSTOM_DONATION_AMOUNT)) {
      if (selectedRadioValue.startsWith(`${CUSTOM_DONATION_AMOUNT}-`)) {
        return selectedRadioValue
      }
      if (value === CUSTOM_DONATION_AMOUNT) {
        return `${value}-${radioInputValue}`
      }
    }
    return value
  }

  const handleChange = (e) => {
    const value = e.target.value
    // Reset del custom value quando si seleziona un'altra opzione
    if (!value.startsWith(CUSTOM_DONATION_AMOUNT)) {
      updateRadioInputValue(1)
    }
    onChange(e)
    setSelectedRadioValue(value)
  }

  const handleKeyDown = (value) => (e) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault()
      handleChange({
        target: {
          value: addCustomValue(value),
        },
      })
      setValue(name, addCustomValue(value))
    }
    if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      e.preventDefault()
      const labels = Array.from(
        e.currentTarget.parentNode.querySelectorAll('label[role="radio"]')
      )
      const index = labels.findIndex((label) => label === e.currentTarget)
      const prevIndex = (index - 1 + labels.length) % labels.length
      labels[prevIndex].focus()
    }
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      e.preventDefault()
      const labels = Array.from(
        e.currentTarget.parentNode.querySelectorAll('label[role="radio"]')
      )
      const index = labels.findIndex((label) => label === e.currentTarget)
      const nextIndex = (index + 1) % labels.length
      labels[nextIndex].focus()
    }
  }

  useEffect(() => {
    const configChecks = Object.keys(config)
    if (selectedRadioValue && configChecks.includes('min')) {
      const _currentValue = selectedRadioValue.startsWith(
        `${CUSTOM_DONATION_AMOUNT}-`
      )
        ? Number(selectedRadioValue.split('-')[2])
        : Number(selectedRadioValue)
      if (_currentValue < config.min.value) {
        setError(name, {
          type: 'custom',
          message: `Il valore minimo è ${config.min.value} €`,
        })
      } else {
        clearErrors(name)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRadioValue, radioInputValue])

  if (radioOptions.length === 0) {
    return null
  }

  return (
    <div
      id={name}
      role='radiogroup'
      aria-labelledby={`${name}-label`}
      className={classNames.radioGroup}
      data-options={radioOptions.length}
      data-variant={name}
      data-current-option={selectedRadioValue.split('-')[0]}
    >
      {radioOptions.map(({ value, label, subLabel }, index) => (
        <Fragment key={value}>
          <label
            id={`${value}-label`}
            key={index}
            tabIndex={index === 0 ? 0 : -1}
            className={`${classNames.radioOption} ${
              selectedRadioValue.startsWith('custom') &&
              value.startsWith('custom')
                ? 'custom'
                : ''
            }`}
            onKeyDown={handleKeyDown(value)}
          >
            <input
              {...register(name)}
              type={isHidden ? 'hidden' : 'radio'}
              aria-labelledby={`${value}-label`}
              name={name}
              value={String(addCustomValue(value))}
              checked={isRadioButtonChecked(value, index)}
              onChange={handleChange}
            />
            <span className={classNames.radioOptionLabel}>{label}</span>
            {subLabel && (
              <span className={classNames.radioOptionSubLabel}>{subLabel}</span>
            )}
          </label>
          {isRadioButtonChecked(value) &&
            value.includes(CUSTOM_DONATION_AMOUNT) && (
              <div className='radio-option_custom-wrapper'>
                <input
                  className={`radio-option_custom-radio ${
                    radioInputValue || selectedRadioValue.startsWith('custom')
                      ? 'active'
                      : ''
                  }`}
                  autoFocus
                  type={isHidden ? 'hidden' : customOptionType}
                  // type={customOptionType}
                  pattern='[0-9]*'
                  min={1}
                  step={0.5}
                  value={radioInputValue}
                  onChange={(e) => {
                    console.log(e.target.value)
                    const isValid =
                      !isNaN(e.target.value) || e.target.value === ''
                    if (!isValid) {
                      return
                    }
                    const value = Number(e.target.value)
                    setValue(name, `${CUSTOM_DONATION_AMOUNT}-${value}`)
                    setSelectedRadioValue(`${CUSTOM_DONATION_AMOUNT}-${value}`)
                    updateRadioInputValue(value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ' ' || e.key === 'Enter') {
                      const _value = e.target.value
                      const isValid =
                        !isNaN(_value) && _value > 0 && value !== null
                      if (!isValid) return
                      setValue(name, `${CUSTOM_DONATION_AMOUNT}-${_value}`)
                      setSelectedRadioValue(
                        `${CUSTOM_DONATION_AMOUNT}-${_value}`
                      )
                      updateRadioInputValue(_value)
                    }
                  }}
                />
                <span
                  className={`radio-option_euro ${
                    radioInputValue || selectedRadioValue.startsWith('custom')
                      ? 'active'
                      : ''
                  }`}
                >
                  €
                </span>
              </div>
            )}
        </Fragment>
      ))}
    </div>
  )
}

function getRadioOptions(
  radioOptions,
  radioOptionsByField,
  formFields,
  defaultValue,
  value
) {
  const hasCustomValue =
    value?.startsWith(`${KNOWN_FORM_VALUES.CUSTOM_DONATION_AMOUNT}-`) ||
    defaultValue?.startsWith(`${KNOWN_FORM_VALUES.CUSTOM_DONATION_AMOUNT}-`) ||
    false

  if (radioOptionsByField === false) {
    return [radioOptions, value || defaultValue]
  }

  const frequencyOptions = radioOptionsByField[DONATION_FREQUENCY]
  if (!frequencyOptions) {
    return [radioOptions, value || defaultValue]
  }

  const frequency = formFields[DONATION_FREQUENCY]
  if (!frequency) {
    return [radioOptions, value || defaultValue]
  }

  // Se è mensile o annuale, usa direttamente quelle opzioni
  if (frequency !== ONE_TIME_DONATION_FREQUENCY) {
    const options = frequencyOptions[frequency]
    const firstOption = options[0]
    const newDefaultValue =
      defaultValue !== firstOption.value && !hasCustomValue
        ? firstOption.value
        : defaultValue
    return [options, newDefaultValue]
  }

  // Per ONE_TIME_DONATION_FREQUENCY, considera anche il tipo di donazione
  const oneTimeOptions = frequencyOptions[ONE_TIME_DONATION_FREQUENCY]
  const donationType = formFields[DONATION_TYPE]
  if (!donationType) {
    return [radioOptions, value || defaultValue]
  }

  const options = oneTimeOptions[donationType]
  const firstOption = options[0]
  const newDefaultValue =
    defaultValue !== firstOption.value && !hasCustomValue
      ? firstOption.value
      : defaultValue
  return [options, newDefaultValue]
}

export default RadioGroup
