const FIELDNAMES = {
  DONATION_TYPE: 'donationType',
  DONATION_AMOUNT: 'donationAmount',
  DONATION_FREQUENCY: 'donationFrequency',
  DONATION_IN_MEMORY_RECEIVER_NAME: 'donationInMemoryReceiverName',
  DONATION_IN_MEMORY_DATE: 'donationInMemoryDate',
  DONATION_IN_MEMORY_LETTER_TO_RELATIVES: 'donationInMemoryLetterToRelatives',
  DONATOR_TYPE: 'donatorType',
  DONATOR_FIRST_NAME: 'donatorFirstName',
  DONATOR_LAST_NAME: 'donatorLastName',
  DONATOR_GENDER: 'donatorGender',
  DONATOR_EMAIL: 'donatorEmail',
  DONATOR_TAX_CODE: 'donatorTaxCode',
  DONATOR_CELLPHONE: 'donatorCellphone',
  DONATOR_COMPANY_NAME: 'donatorCompanyName',
  DONATOR_COMPANY_TAX_CODE: 'donatorCompanyTaxCode',
  DONATOR_COMPANY_EMAIL: 'donatorCompanyEmail',
  DONATOR_COMPANY_TELEPHONE: 'donatorCompanyTelephone',
  DONATOR_COMPANY_CELLPHONE: 'donatorCompanyCellphone',
  DONATOR_ADDRESS: 'donatorAddress',
  DONATOR_ADDRESS_NUMBER: 'donatorAddressNumber',
  DONATOR_COUNTRY: 'donatorCountry',
  DONATOR_POSTAL_CODE: 'donatorPostalCode',
  DONATOR_CITY: 'donatorCity',
  DONATOR_PROVINCE: 'donatorProvince',
  DONATION_PAYMENT_METHOD_ON_RECURRENCE:
    'donationPaymentMethodOnRecurrenceType',
  DONATION_PAYMENT_METHOD_ON_RECURRENCE_IBAN:
    'donationPaymentMethodOnRecurrenceIban',
  DONATION_PAYMENT_METHOD_ON_RECURRENCE_IBAN_IS_OWNER:
    'donationPaymentMethodOnRecurrenceIbanIsOwner',
  DONATION_IBAN_OWNER_FIRST_NAME: 'donationIbanOwnerFirstName',
  DONATION_IBAN_OWNER_LAST_NAME: 'donationIbanOwnerLastName',
  DONATION_IBAN_OWNER_EMAIL: 'donationIbanOwnerEmail',
  DONATION_IBAN_OWNER_TAX_CODE: 'donationIbanOwnerTaxCode',
  DONATOR_LETTER_FORMAT: 'donatorLetterFormat',
  DONATOR_PAYMENT_METHOD: 'donatorPaymentMethod',
  DONATION_LETTER_PARTICIPANTS: 'donationLetterPartecipants',
  DONATION_LETTER_RECIPIENT_COURTESY_TITLE:
    'donationLetterRecipientCourtesyTitle',
  DONATION_LETTER_RECIPIENT_NAME: 'donationLetterRecipientName',
  DONATION_LETTER_RECIPIENT_EMAIL: 'donationLetterRecipientEmail',
  DONATION_PRIVACY: 'privacy',
  DONATION_TERMS: 'termsAndConditions',
  DONATION_NEWSLETTER: 'newsletter',
  DONATION_LETTER_RECIPIENT_ADDRESS: 'donationLetterRecipientAddress',
  DONATION_LETTER_RECIPIENT_ADDRESS_NUMBER:
    'donationLetterRecipientAddressNumber',
  DONATION_LETTER_RECIPIENT_POSTAL_CODE: 'donationLetterRecipientPostalCode',
  DONATION_LETTER_RECIPIENT_CITY: 'donationLetterRecipientCity',
  DONATION_LETTER_RECIPIENT_PROVINCE: 'donationLetterRecipientProvince',
  DONATION_LETTER_RECIPIENT_COUNTRY: 'donationLetterRecipientCountry',
}

const INPUT_TYPES = {
  RADIO_GROUP: 'radio-group',
  RADIO: 'radio',
  TEXT: 'text',
  DATE: 'date',
  SELECT: 'select',
  EMAIL: 'email',
  TELEPHONE: 'tel',
  CHECKBOX: 'checkbox',
  PAYMENT_METHOD: 'payment-method',
  INPUT_ADDER: 'input-adder',
}

const KNOWN_FORM_VALUES = {
  FREE_DONATION_TYPE: 'free',
  IN_MEMORY_DONATION_TYPE: 'in-memory',
  ONE_TIME_DONATION_FREQUENCY: 'one-time',
  MONTHLY_DONATION_FREQUENCY: 'monthly',
  YEARLY_DONATION_FREQUENCY: 'yearly',
  CUSTOM_DONATION_AMOUNT: 'custom-number',
  YES: 'yes',
  NO: 'no',
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
  DEFAULT: 'default',
  MALE: 'M',
  FEMALE: 'F',
  CARD: 'card',
  IBAN: 'iban',
  FALSE: false,
  TRUE: true,
  DIGITAL: 'digital',
  PAPER: 'paper',
}
// `${KNOWN_FORM_VALUES.CUSTOM_DONATION_AMOUNT}-`
export { FIELDNAMES, INPUT_TYPES, KNOWN_FORM_VALUES }
