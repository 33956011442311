import { COUNTRIES } from '@/utils/constants'
import { FIELDNAMES, INPUT_TYPES, KNOWN_FORM_VALUES } from './constants'

const {
  DONATION_AMOUNT,
  DONATOR_CELLPHONE,
  DONATOR_COMPANY_TELEPHONE,
  DONATOR_COMPANY_CELLPHONE,
  DONATION_LETTER_PARTICIPANTS,
} = FIELDNAMES
const {
  FREE_DONATION_TYPE,
  CUSTOM_DONATION_AMOUNT,
  IN_MEMORY_DONATION_TYPE,
  ONE_TIME_DONATION_FREQUENCY,
  MONTHLY_DONATION_FREQUENCY,
  YEARLY_DONATION_FREQUENCY,
  YES,
  NO,
  INDIVIDUAL,
  COMPANY,
  MALE,
  FEMALE,
  DEFAULT,
  DIGITAL,
  PAPER,
  TRUE,
  FALSE,
  CARD,
  IBAN,
} = KNOWN_FORM_VALUES

function getDefaultValues(arr) {
  const defaultValues = {}
  arr.forEach((item) => {
    if (item.fieldName && item.defaultValue !== undefined) {
      defaultValues[item.fieldName] = item.defaultValue
    }
  })
  return defaultValues
}

const operators = {
  equals: (fieldValue, value) => fieldValue === value,
  notEquals: (fieldValue, value) => fieldValue !== value,
  includes: (fieldValue, value) =>
    Array.isArray(value) && value.includes(fieldValue),
}

function evaluateCondition(condition, values) {
  const fieldValue = values[condition.field] ?? false
  if (!fieldValue) {
    if (condition.fields && Array.isArray(condition.fields)) {
      return condition.fields.every((fieldCondition) => {
        const value = values[fieldCondition.field] ?? false
        for (const [operator, operatorValue] of Object.entries(
          fieldCondition
        ).filter(([key]) => key !== 'field')) {
          const operatorFn = operators[operator]
          if (operatorFn && !operatorFn(value, operatorValue)) {
            return false
          }
        }
        return true
      })
    }
    return false
  }
  for (const [operator, value] of Object.entries(condition).filter(
    ([key]) => key !== 'field'
  )) {
    const operatorFn = operators[operator]
    if (operatorFn && operatorFn(fieldValue, value)) {
      return true
    }
  }
  return false
}

function groupFieldsByStep(fields) {
  return fields
    .filter((f) => !!f.uiOptions && !!f.uiOptions.step)
    .reduce((acc, field) => {
      const { fieldName, uiOptions } = field ?? { fieldName: '', uiOptions: {} }
      const { step } = uiOptions ?? { step: 0 }

      if (!acc[step]) {
        acc[step] = []
      }
      acc[step].push(field)
      return acc
    }, {})
}
function groupFieldsByEdit(fields, currentStep = null) {
  const fieldByEdit = fields
    .filter((f) => !!f.uiOptions && !!f.uiOptions.edit)
    .reduce((acc, field) => {
      const { fieldName, uiOptions } = field ?? { fieldName: '', uiOptions: {} }
      const { edit, step } = uiOptions ?? { edit: 0 }

      // check if the field is in the current step or less
      if (currentStep !== null) {
        if (step >= currentStep) {
          return acc
        }
      }

      if (!acc[edit]) {
        acc[edit] = []
      }
      acc[edit].push(field)
      return acc
    }, {})
  return fieldByEdit
}

function isPaymentStep(currentStep, stepConfig) {
  const step = stepConfig[currentStep]
  return (step && step.isPaymentStep) || false
}

function isLetterPreviewStep(currentStep, stepConfig) {
  const step = stepConfig[currentStep]
  return (step && step.isLetterPreviewStep) || false
}

function isRecurrencePaymentStep(currentStep, stepConfig) {
  const step = stepConfig[currentStep]
  return (step && step.isRecurrencePaymentStep) || false
}

function getValueToDisplay(
  value,
  { radioOptions = [], selectOptions = [], inputType, fieldName }
) {
  if (inputType === 'form-content') return

  if (inputType === INPUT_TYPES.INPUT_ADDER) {
    if (typeof value === 'string') {
      return value || 'Me stesso'
    }
    if (Array.isArray(value)) {
      return value.map((item) => item.value).join(', ') || 'Me stesso'
    }
  }

  if (inputType === INPUT_TYPES.DATE) {
    return value ? new Date(value).toLocaleDateString() : ''
  }

  const findLabel = (options) => {
    if (typeof options === 'string') {
      if (options === 'COUNTRIES_LIST') {
        return (
          COUNTRIES.find((country) => country.id === value)?.label ?? DEFAULT
        )
      }
      return options.find((option) => option.value === value)?.label ?? DEFAULT
    }
  }

  if (radioOptions.length > 0) {
    const label = findLabel(radioOptions)
    if (label) return label
  }

  if (selectOptions.length > 0) {
    const label = findLabel(selectOptions)
    if (label) return label
  }

  if (typeof value === 'string') {
    const mapping = {
      [ONE_TIME_DONATION_FREQUENCY]: 'Singola',
      [MONTHLY_DONATION_FREQUENCY]: 'Mensile',
      [YEARLY_DONATION_FREQUENCY]: 'Annuale',
      [FREE_DONATION_TYPE]: 'Libera',
      [IN_MEMORY_DONATION_TYPE]: 'In memoria',
      [COMPANY]: 'Persona giuridica',
      [INDIVIDUAL]: 'Persona fisica',
      [YES]: 'Sì',
      [NO]: 'No',
      [TRUE]: 'Accettato',
      [FALSE]: 'Non accettato',
      [CARD]: 'Carta di credito',
      [IBAN]: 'Bonifico bancario',
      [PAPER]: 'Cartaceo',
      [DIGITAL]: 'Digitale',
      [MALE]: 'Maschio',
      [FEMALE]: 'Femmina',
    }

    if (mapping[value]) return mapping[value]
  }

  if (typeof value === 'boolean') {
    return value ? 'Accettato' : 'Non accettato'
  }

  if (fieldName === DONATION_AMOUNT) {
    if (typeof value === 'string') {
      if (value?.startsWith(`${CUSTOM_DONATION_AMOUNT}-`)) {
        return `${value.replace(`${CUSTOM_DONATION_AMOUNT}-`, '')} €`
      }
    }
    return `${value} €`
  }

  return value
}

function getLabelToDisplay(label, { uiOptions }) {
  return uiOptions?.recapLabel || label || ''
}

function getFieldsEdits(formState, formFields) {
  const editFields = new Map(
    Object.entries(formState).filter(([fieldName]) =>
      fieldName.includes('-edit')
    )
  )
  const originalFields = new Map(
    Object.entries(formState).filter(
      ([fieldName]) => !fieldName.includes('-edit')
    )
  )
  const fieldNameToExclude = { includes: [`${DONATION_LETTER_PARTICIPANTS}-`] }

  const changes = Array.from(editFields).reduce(
    (acc, [fieldName, fieldValue]) => {
      const originalFieldName = fieldName.replace('-edit', '')
      const { includes = [] } = fieldNameToExclude

      const isExcluded = includes.some((exclude) =>
        originalFieldName.startsWith(exclude)
      )
      if (isExcluded) return acc
      const originalValue = originalFields.get(originalFieldName)
      const fieldProps =
        formFields.find((field) => field.fieldName === originalFieldName) || {}

      if (
        originalValue !== fieldValue &&
        JSON.stringify(originalValue) !== JSON.stringify(fieldValue) &&
        originalValue !== undefined &&
        originalValue !== '' &&
        originalValue !== null &&
        fieldValue !== undefined &&
        fieldValue !== null &&
        fieldValue !== ''
      ) {
        acc[originalFieldName] = {
          ...fieldProps,
          newValue: fieldValue,
          oldValue: originalValue,
        }
      }
      return acc
    },
    {}
  )
  changes.editNumber = Object.keys(changes).length
  return changes
}

function clearAllEditFields(formState, unregister, clearErrors, reset) {
  const editFields = Object.entries(formState)
    .filter(([fieldName]) => fieldName.includes('-edit'))
    .map(([fieldName, fieldValue]) => fieldName)
  if (editFields.length < 1) return
  clearErrors(editFields)
  unregister(editFields)
}

function getAllWatchedFields(fields) {
  return [
    ...new Set(
      fields
        .map((field) => field.uiOptions?.watchFields)
        .map((watchFields) => {
          if (!watchFields) return
          return watchFields.map(({ field }) => field)
        })
        .flat()
        .filter((field) => field)
    ),
  ]
}

function getFieldNamesInView(fields, step, watch = () => {}) {
  // Group fields by their respective steps
  const fieldGroupedByStep = groupFieldsByStep(fields)

  // If there are no fields for the given step, return an empty array
  if (!fieldGroupedByStep[step]) return []

  const fieldNames = fieldGroupedByStep[step]
    .filter((field) => {
      // Check if the field has any watchFields/sub-fields defined in its uiOptions
      const isWatching = field.uiOptions?.watchFields?.length > 0 || false

      if (isWatching) {
        const watchList = field.uiOptions?.watchFields?.map((watchField) => {
          if (watchField.includes && watchField.includes.length > 0) {
            const { field, includes } = watchField
            const watchedValue = watch(field)
            return watchedValue ? includes.includes(watchedValue) : false
          }
          if (watchField.equals !== undefined) {
            const { field, equals } = watchField
            const watchedValue = String(watch(field))
            return watchedValue || watchedValue === ''
              ? equals === watchedValue
              : false
          }
        })
        return watchList.every(Boolean)
      } else {
        return true
      }
    })
    .map((field) => field.fieldName)

  return fieldNames
}

function getFieldNamesInRecapAccordion(
  stepHistory,
  formFields,
  watch,
  getValues
) {
  const fieldNamesInRecapAccordion = stepHistory
    .map((step) => {
      return getFieldNamesInView(formFields, step, watch)
    })
    .flat()

  const fieldsInRecapAccordion = fieldNamesInRecapAccordion.map((fieldName) => {
    return {
      ...formFields.find((field) => field.fieldName === fieldName),
      value: getValues(fieldName),
    }
  })

  const { grouped: groupedFieldValues, excludedGroupedFields } =
    getGroupedFieldValues(fieldsInRecapAccordion, getValues)

  const ungroupedFields = fieldsInRecapAccordion.filter(
    (field) => !field.uiOptions?.group
  )
  const _fieldsInRecapAccordion = [
    ...ungroupedFields,
    ...Object.values(excludedGroupedFields),
    ...Object.values(groupedFieldValues),
  ]

  return _fieldsInRecapAccordion
}
function getGroupedFieldValues(
  fields,
  getValues,
  excludedFieldsGroup = ['anagraphicFields', 'companyFields', 'ibanFields']
) {
  const excludedGroupedFields = []
  const grouped = fields.reduce((acc, field) => {
    const group = field.uiOptions?.group
    if (excludedFieldsGroup.includes(group)) {
      const item = {
        label: field?.label || '',
        value: getValues(field.fieldName) || '',
        fieldName: field.fieldName || '',
      }
      if (!item.label === '') return acc
      excludedGroupedFields.push(item)
      return acc
    }
    const groupLabel =
      field.uiOptions?.groupLabel ||
      field.uiOptions?.editGroupLabel ||
      field.uiOptions?.recapLabel ||
      ''
    if (group) {
      if (!acc[group]) {
        acc[group] = {
          label: groupLabel,
          value: [],
          fieldName: group,
        }
      }
      const valueField = getValues(field.fieldName)
      const value = getValueToDisplay(valueField, field)
      acc[group].value.push(value)
    }
    return acc
  }, {})
  for (const [group, { value }] of Object.entries(grouped)) {
    grouped[group].value = value.join(' ')
  }
  return { grouped, excludedGroupedFields }
}

function formHasPhoneNumbers(formValues) {
  return Boolean(
    formValues[DONATOR_CELLPHONE] ||
      formValues[DONATOR_COMPANY_CELLPHONE] ||
      formValues[DONATOR_COMPANY_TELEPHONE]
  )
}

export {
  clearAllEditFields,
  evaluateCondition,
  formHasPhoneNumbers,
  getAllWatchedFields,
  getDefaultValues,
  getFieldNamesInRecapAccordion,
  getFieldNamesInView,
  getFieldsEdits,
  getLabelToDisplay,
  getValueToDisplay,
  groupFieldsByEdit,
  groupFieldsByStep,
  isLetterPreviewStep,
  isPaymentStep,
  isRecurrencePaymentStep,
}
