import {
  Checkbox,
  Input,
  InputAdder,
  RadioGroup,
  Select,
} from 'components/molecules'
import { PaymentMethods } from 'components/organism'
import { INPUT_TYPES } from './utils/constants'

const {
  RADIO_GROUP,
  TEXT,
  DATE,
  SELECT,
  EMAIL,
  TELEPHONE,
  CHECKBOX,
  PAYMENT_METHOD,
  INPUT_ADDER,
} = INPUT_TYPES
export const DynamicControl = ({
  inputType,
  fieldName,
  defaultValue,
  options = [],
  config = {},
  isHidden = false,
  useFormContextIfAvailable = true,
  triggerError = false,
  ...rest
}) => {
  switch (inputType) {
    case TEXT:
    case DATE:
    case EMAIL:
    case TELEPHONE:
      return (
        <Input
          {...rest}
          type={inputType}
          label={fieldName}
          name={fieldName}
          defaultValue={defaultValue}
          isHidden={isHidden}
          config={config}
          useFormContextIfAvailable={useFormContextIfAvailable}
          triggerError={triggerError}
        />
      )
    case CHECKBOX:
      return (
        <Checkbox
          {...rest}
          label={fieldName}
          name={fieldName}
          defaultValue={defaultValue}
          isHidden={isHidden}
          config={config}
          useFormContextIfAvailable={useFormContextIfAvailable}
        />
      )
    case SELECT: {
      return (
        <Select
          {...rest}
          label={fieldName}
          name={fieldName}
          defaultValue={defaultValue}
          isHidden={isHidden}
          config={config}
          useFormContextIfAvailable={useFormContextIfAvailable}
          triggerError={triggerError}
        />
      )
    }
    case RADIO_GROUP:
      return (
        <RadioGroup
          disableQueryState={rest.disableQueryState}
          radioOptions={rest.radioOptions}
          defaultValue={defaultValue}
          name={fieldName}
          isHidden={isHidden}
          config={config}
          useFormContextIfAvailable={useFormContextIfAvailable}
          radioOptionsByField={rest.radioOptionsByField}
        />
      )
    case PAYMENT_METHOD:
      return (
        <PaymentMethods
          key={fieldName}
          name={fieldName}
          isHidden={isHidden}
          config={config}
          {...rest}
        />
      )
    case INPUT_ADDER:
      return (
        <InputAdder
          {...rest}
          key={fieldName}
          label={fieldName}
          defaultValue={defaultValue}
          name={fieldName}
          isHidden={isHidden}
          config={config}
          useFormContextIfAvailable={useFormContextIfAvailable}
        />
      )
    case 'form-content':
      switch (fieldName) {
        case 'text-content':
          return
        default:
          return <span>to be done</span>
      }

    default:
      return <span>to be done</span>
  }
}
