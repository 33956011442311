'use client'

import { RequiredFormStar } from '@/svg'
import { ErrorMessage } from '@hookform/error-message'
import { Avatar } from 'components/atoms'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { DynamicControl } from './DynamicControl'

const Fieldset = ({
  className,
  fieldProps: _fieldProps,
  errors,
  currentStep,
  tabIndex,
  disableQueryState = false,
  useFormContextIfAvailable = true,
  isWatched,
  isFieldInView,
}) => {
  const {
    watch,
    register,
    unregister,
    resetField,
    setValue,
    formState: { defaultValues },
    ...rest
  } = useFormContext()
  const {
    uiOptions = {},
    subLabel,
    label,
    fieldName,
    defaultValue,
    config,
  } = _fieldProps

  const fieldProps = {
    ..._fieldProps,
    defaultValue: defaultValues[fieldName] || defaultValue,
  }

  const isFieldWatching = uiOptions.watchFields?.length > 0
  const showAvatars = uiOptions.showAvatars === true
  const showLabel = uiOptions.showLabel !== false
  const showSubLabel = uiOptions.showSubLabel !== false && subLabel
  const isFieldRequired =
    config?.required === true || config?.required?.value === true || false

  const generateWatchList = () => {
    const watchList =
      uiOptions?.watchFields?.map((watchField) => {
        if (watchField.includes && watchField.includes.length > 0) {
          const { field, includes } = watchField
          const watchedValue = watch(field)
          return watchedValue
            ? includes.includes(watchedValue)
            : defaultValues[fieldName]
        }
        if (watchField.equals !== undefined) {
          const { field, equals } = watchField
          const watchedValue = String(watch(field))
          const watchedValueExists = watchedValue || watchedValue === ''
          return watchedValueExists
            ? String(equals) === watchedValue
            : (defaultValues[fieldName] ?? false)
        }
      }) || []
    return watchList
  }

  const fieldWatchList = isFieldWatching || isWatched ? generateWatchList() : []

  const shouldShowFieldNow = fieldWatchList.every(Boolean)

  useEffect(() => {
    if (!shouldShowFieldNow) {
      unregister(fieldName)
    }
  }, [fieldName, shouldShowFieldNow, unregister])

  return (
    <div
      className={`${className} ${!shouldShowFieldNow ? 'hidden' : 'show'} ${
        errors[fieldName] ? 'error' : ''
      }`}
    >
      {showAvatars && <Avatar numberOfAvatar={3} />}
      {showLabel && (
        <label
          // tabIndex={tabIndex}
          id={`${fieldName}-label`}
          className={`${className}_label`}
          htmlFor={fieldName}
        >
          {label}
          {isFieldRequired ? <RequiredFormStar /> : null}
        </label>
      )}
      {showSubLabel && (
        <span className={`${className}_sublabel`}>{subLabel}</span>
      )}
      <DynamicControl
        {...fieldProps}
        disableQueryState={disableQueryState}
        isHidden={!shouldShowFieldNow}
        useFormContextIfAvailable={useFormContextIfAvailable}
        triggerError={errors[fieldName]}
      />
      {errors[fieldName] && (
        <span className={`${className}_error`}>
          <ErrorMessage
            errors={errors}
            name={fieldName}
          />
        </span>
      )}
    </div>
  )
}

export default Fieldset
